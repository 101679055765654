/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Map from "../map/Map";
import List from "../dashboard/List";
import OUTLET_MERCHANDISING_LIST from "../merchandising/OUTLET_MERCHANDISING_LIST";

/*REDUX FUNCTION*/
import {
  get_competitor,
  get_salesman_detail,
  get_competitor_submitted,
  get_template_list,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class Main extends Component {
  componentDidMount() {
    const str = this?.props?.location?.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    this.props.get_salesman_detail(params);
    const { toko_list_submitted } = this.props.data;

    if (toko_list_submitted.length === 0) {
      this.props.get_competitor_submitted(params);
    }
    if (this.props.data.template_list.length === 0) {
      this.props.get_template_list();
    }
  }

  render() {
    //local storage
    const str = this?.props?.location?.search;
    const params = Object.fromEntries(new URLSearchParams(str));

    //local state

    //global props
    const { menu, salesman_detail } = this.props.data;

    //content

    let content;

    if (menu === "map") {
      content = <Map params={params} />;
    } else if (menu === "merchandising") {
      content = <OUTLET_MERCHANDISING_LIST />;
    } else {
      if (salesman_detail._id) {
        content = <List params={params} />;
      } else {
        content = (
          <main className="main_body" style={{ marginTop: "100px" }}>
            <section className="main_container">Memuat data salesman</section>
          </main>
        );
      }
    }
    return content;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  get_competitor,
  get_salesman_detail,
  get_competitor_submitted,
  get_template_list,
})(Main);
