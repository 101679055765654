import { combineReducers } from "redux";
import dataReducer from "./dataReducer";
import mapReducer from "./mapReducer";
import outletReducer from "./outletReducer";

export default combineReducers({
  data: dataReducer,
  map: mapReducer,
  outlet: outletReducer,
});
