/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal.js";
import TextFieldGroup from "../common/TextFieldGroup.js";
import TokoListSubmit from "./TokoListSubmit.js";

/*REDUX FUNCTION*/
import {
  push_salesman_to_toko_new,
  push_gagal_akuisisi,
} from "../../actions/dataActions.js";

/*PICTURE ASSET*/
import icon_success from "../../img/icon_success.png";

/*GENERAL*/

/*NON IMPORT*/

class TokoPopup extends Component {
  state = {
    modal_submitted_toko: false,
    modal_akuisisi: false,
    modal_urutkan: false,

    radius: 1,
    alert_radius_max: false,
    alert_radius_min: false,
    radius_min: 1,
    radius_max: 20,

    toko_selected: {},
    hari: "R01",
    freq: "M2C13",
    kode_sales: "",
    div: "",

    mode_submit: "success", //success | failed
    failed_reason: "",
    template_list: [],
    id_nabati: "",
  };

  toggle_submitted_toko = () => {
    this.setState({ modal_submitted_toko: !this.state.modal_submitted_toko });
  };

  set_mode_submit = (mode_submit) => {
    this.setState({ mode_submit });
  };

  on_submit_gagal = () => {
    const {
      // hari, freq, kode_sales, div,
      toko_selected,
      failed_reason,
    } = this.state;
    const outlet_id = toko_selected._id;
    const { salesman_detail } = this.props.data;
    const salesman_id = salesman_detail?._id;
    const salesman_nik = salesman_detail?.NIK;
    const salesman_name = salesman_detail?.["Nama Salesman"];
    const body = {
      outlet_id,
      failed_reason,
      salesman_id,
      salesman_nik,
      NamaSales: salesman_name,

      // HARIKunjungan: hari,
      // hari_new: hari,
      // FreqVisit: freq,
      // KodeSales: kode_sales,
      // div,
    };
    this.props.push_gagal_akuisisi(body);
  };

  on_submit_akuisisi = () => {
    const { hari, freq, kode_sales, div, toko_selected, id_nabati } =
      this.state;
    const outlet_id = toko_selected._id;
    const { salesman_detail } = this.props.data;
    const salesman_id = salesman_detail?._id;
    const salesman_nik = salesman_detail?.NIK;
    const salesman_name = salesman_detail?.["Nama Salesman"];
    const body = {
      outlet_id,
      salesman_id,
      salesman_nik,
      NamaSales: salesman_name,
      HARIKunjungan: hari,
      hari_new: hari,
      FreqVisit: freq,
      KodeSales: kode_sales,
      div,
      id_nabati,
    };
    this.props.push_salesman_to_toko_new(body);
  };

  on_change = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  toggle_akuisisi = (toko_selected) => {
    this.setState({
      modal_akuisisi: !this.state.modal_akuisisi,
      failed_reason: this.props.data?.template_list?.[0]?.text,
    });
    if (toko_selected) {
      this.setState({ toko_selected });
    }
    const { salesman_detail } = this.props.data;
    const kode_sales = salesman_detail?.["kd_sales_arr"]?.[0];
    const div = salesman_detail?.["divisi_arr"]?.[0];
    this.setState({ kode_sales, div });
  };
  render() {
    //local storage

    //local state
    const {
      modal_akuisisi,
      modal_submitted_toko,
      hari,
      freq,
      kode_sales,
      div,
      mode_submit,
      failed_reason,
      id_nabati,
    } = this.state;

    //global props
    const { salesman_detail, loading_status, loading_item, toko_list } =
      this.props.data;
    const toko_id = this.props.event.features[0]?.properties._id;

    //content
    const toko_selected = toko_list.find((t) => t._id === toko_id);
    let pilih_kode_sales_content;
    let pilih_divisi_content;
    if (
      (loading_status && loading_item === "get_salesman_detail") ||
      !salesman_detail._id
    ) {
    } else {
      if (salesman_detail?.["kd_sales_arr"]?.length > 1) {
        const kode_sales_array = salesman_detail["kd_sales_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_kode_sales_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih kode sales"
            name="kode_sales"
            id="kode_sales"
            value={kode_sales}
            onChange={this.on_change}
            list_selection_object={kode_sales_array}
            name_list="name"
            value_list="value"
          />
        );
      } else if (salesman_detail?.["kd_sales_arr"]?.length > 0) {
        const kode_sales_array = salesman_detail["kd_sales_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_kode_sales_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih kode sales"
            name="kode_sales"
            id="kode_sales"
            value={kode_sales}
            onChange={this.on_change}
            list_selection_object={kode_sales_array}
            name_list="name"
            value_list="value"
          />
        );
      } else {
        pilih_kode_sales_content = (
          <section>
            Kamu belum memiliki kode sales, tambah di sini (coming soon)
          </section>
        );
      }
      if (salesman_detail?.["divisi_arr"]?.length > 1) {
        const div_array = salesman_detail["divisi_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_divisi_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih divisi"
            name="div"
            id="div"
            value={div}
            onChange={this.on_change}
            list_selection_object={div_array}
            name_list="name"
            value_list="value"
          />
        );
      } else if (salesman_detail?.["divisi_arr"]?.length > 0) {
        const div_array = salesman_detail["divisi_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_divisi_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih divisi"
            name="div"
            id="div"
            value={div}
            onChange={this.on_change}
            list_selection_object={div_array}
            name_list="name"
            value_list="value"
          />
        );
      } else {
        pilih_divisi_content = (
          <main>Kamu belum divisi, tambahkan divisi di sini (coming soon)</main>
        );
      }
    }
    const hari_array = [
      {
        value: "R01",
        name: "R01",
      },
      {
        value: "R02",
        name: "R02",
      },
      {
        value: "R03",
        name: "R03",
      },
      {
        value: "R04",
        name: "R04",
      },
      {
        value: "R05",
        name: "R05",
      },
      {
        value: "R06",
        name: "R06",
      },
    ];
    //M2C13    M2C24;
    const freq_array = [
      {
        value: "M2C13",
        name: "Ganjil (M2C13)",
      },
      {
        value: "M2C24",
        name: "Genap (M2C24)",
      },
    ];
    let button_akuisisi; //button di atas pop pup peta untuk toggle akuisisi
    let kunjungan_arr = toko_selected?.kunjungan_arr
      ? toko_selected?.kunjungan_arr
      : [];
    let salesman_nik_array = kunjungan_arr.map((k) => k.salesman_nik);
    if (salesman_nik_array.includes(salesman_detail.NIK)) {
      button_akuisisi = (
        <div className="button mr-2 mb-2" id="grey">
          Sudah kamu submit
        </div>
      );
    } else if (kunjungan_arr.length > 0) {
      button_akuisisi = (
        <div className="button mr-2 mb-2" id="grey">
          Sudah diakuisisi salesman lain
        </div>
      );
    } else {
      button_akuisisi = (
        <button
          className="button"
          id="green"
          onClick={this.toggle_akuisisi.bind(this, toko_selected)}
        >
          akuisisi toko
        </button>
      );
    }
    let button_submit_akuisisi = (
      <button onClick={this.on_submit_akuisisi} className="button" id="green">
        Submit akuisisi
      </button>
    );
    if (loading_status && loading_item === "push_salesman_to_toko") {
      button_submit_akuisisi = (
        <div className="button" id="grey">
          Mengirim akuisisi...
        </div>
      );
    } else if (salesman_nik_array.includes(salesman_detail.NIK)) {
      button_submit_akuisisi = (
        <section>
          <img
            alt="success"
            src={icon_success}
            width="100px"
            style={{ margin: "auto" }}
          />
          <div className="text_bold">Berhasil submit akuisisi</div>
          <br />
          <button
            className="button mt-2"
            id="blue"
            onClick={this.toggle_submitted_toko}
          >
            Lihat toko-toko akusisi
          </button>
        </section>
      );
    }
    let button_submit_gagal = (
      <button onClick={this.on_submit_gagal} className="button" id="green">
        Submit alasan tutup/tunda
      </button>
    );
    if (loading_status && loading_item === "push_gagal_akuisisi") {
      button_submit_gagal = (
        <div className="button" id="grey">
          Mengirim gagal akuisisi...
        </div>
      );
    } else if (salesman_nik_array.includes(salesman_detail.NIK)) {
      button_submit_gagal = (
        <section>
          <img
            alt="success"
            src={icon_success}
            width="100px"
            style={{ margin: "auto" }}
          />
          <div className="text_bold">Berhasil submit gagal/tunda</div>
          <br />
          <button
            className="button mt-2"
            id="blue"
            onClick={this.toggle_submitted_toko}
          >
            Lihat pelaporan toko-toko
          </button>
        </section>
      );
    }
    const modal_akuisisi_content = modal_akuisisi && (
      <Modal
        modalSize="large"
        isOpen={modal_akuisisi}
        onClose={this.toggle_akuisisi}
      >
        <div className="box-body">
          <section className="text_bold text_center mb-5">
            <p className="mb-5">Akuisisi toko kompetitor</p>
            <section>
              <button
                onClick={this.set_mode_submit.bind(this, "success")}
                className="button mr-3"
                id={mode_submit === "success" ? "green" : "grey"}
              >
                Berhasil
              </button>
              <button
                onClick={this.set_mode_submit.bind(this, "failed")}
                className="button"
                id={mode_submit === "failed" ? "green" : "grey"}
              >
                Tunda/tutup
              </button>
            </section>
          </section>
          <main className="main_container mb-5">
            <section className="text_bold">
              {toko_selected["Nama Toko"]}
            </section>
            <section>
              <p className="text_inferior">Alamat</p>
              <p className="text_medium">{toko_selected["ALAMAT"]}</p>
              <p className="text_medium">{toko_selected["KELURAHAN"]}</p>
              <p className="text_medium">{toko_selected["KECAMATAN"]}</p>
              <p className="text_medium">{toko_selected["KABUPATEN"]}</p>
              <p className="text_medium">{toko_selected["PROPINSI"]}</p>
              <p className="text_medium">{toko_selected["KDPOS"]}</p>
            </section>
          </main>
          {mode_submit === "success" ? (
            // mode success input form akuisisi
            <main>
              <main>
                <TextFieldGroup
                  type="selection"
                  placeholder="Pilih hari kunjungan"
                  name="hari"
                  id="hari"
                  value={hari}
                  onChange={this.on_change}
                  list_selection_object={hari_array}
                  name_list="name"
                  value_list="value"
                />
                <TextFieldGroup
                  type="selection"
                  placeholder="Pilih frekuensi kunjungan"
                  name="freq"
                  id="freq"
                  value={freq}
                  onChange={this.on_change}
                  list_selection_object={freq_array}
                  name_list="name"
                  value_list="value"
                />
                {pilih_kode_sales_content}
                {pilih_divisi_content}
                <TextFieldGroup
                  type="text"
                  placeholder="Isikan id toko baru hasil akusisi"
                  name="id_nabati"
                  id="id_nabati"
                  value={id_nabati}
                  onChange={this.on_change}
                />
              </main>
              <section className="text_center">
                {button_submit_akuisisi}
              </section>
            </main>
          ) : (
            //mode gagal pilih alasan gagal akuisisi
            <main>
              <TextFieldGroup
                type="selection"
                placeholder="Pilih alasan tunda/tutup"
                name="failed_reason"
                id="failed_reason"
                value={failed_reason}
                onChange={this.on_change}
                list_selection_object={this.props.data.template_list}
                name_list="text"
                value_list="text"
              />
              <section className="text_center">{button_submit_gagal}</section>
            </main>
          )}
        </div>
      </Modal>
    );

    const modal_submitted_toko_content = modal_submitted_toko && (
      <Modal
        modalSize="large"
        isOpen={modal_submitted_toko}
        onClose={this.toggle_submitted_toko}
      >
        <div className="box-body" style={{ minHeight: "80vh" }}>
          <TokoListSubmit />
        </div>
      </Modal>
    );
    return (
      <div>
        <main
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "0px 10px 0px 0px",
          }}
        >
          <section className="text_inferior">Nama Toko</section>
          <section className="text_bold mb-5">
            {toko_selected["Nama Toko"]}
          </section>
          {button_akuisisi}
        </main>
        {modal_akuisisi_content}
        {modal_submitted_toko_content}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  push_salesman_to_toko_new,
  push_gagal_akuisisi,
})(TokoPopup);
