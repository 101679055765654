const initialState = {
  map: {},
  outletView: true,
  trackingView: true,
  drawMode: "simple_select",
  polygonList: [],
  pointByPolygon: [],
  selectedDrawFeature: {},
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_MAP":
      return {
        ...state,
        map: action.payload,
      };
    case "SET_OUTLET_VIEW":
      return {
        ...state,
        outletView: action.payload,
      };
    case "SET_TRACKING_VIEW":
      return {
        ...state,
        trackingView: action.payload,
      };
    case "SET_DRAW_MODE":
      return {
        ...state,
        drawMode: action.payload,
      };
    case "ADD_POLYGON":
      return {
        ...state,
        polygonList: [action.payload, ...state.polygonList],
        drawMode: "simple_select",
      };
    case "ADD_POINT_RESULT":
      return {
        ...state,
        pointByPolygon: [...state.pointByPolygon, action.payload],
      };
    case "CHANGE_POLYGON":
      return {
        ...state,
        polygonList: action.payload,
      };

    case "SELECTED_DRAW_FEATURE":
      return {
        ...state,
        selectedDrawFeature: action.payload,
      };

    case "REPLACE_POINT":
      return {
        ...state,
        pointByPolygon: replace_point(action.payload, state.pointByPolygon),
      };

    case "DELETE_DATA_POINT":
      return {
        ...state,
        polygonList: replace_id(action.payload, state.polygonList),
      };

    case "CHANGE_HARI":
      return {
        ...state,
        polygonList: replace_hari(action.payload, state.polygonList),
      };

    default:
      return state;
  }
}

const replace_point = ({ idx, feature }, pointByPolygon) => {
  let newPointByPolygon = [...pointByPolygon];
  newPointByPolygon[idx] = feature;
  return newPointByPolygon;
};

const replace_id = (id, polygonList) => {
  let cloneId = [...polygonList];
  const newId = cloneId.filter((e) => e.properties.hari !== id);
  return newId;
};

const replace_hari = ({ old, newHari }, polygonList) => {
  let cloneId = [...polygonList];
  const oldId = cloneId.findIndex((e) => e.properties.hari === old);
  const newId = cloneId.findIndex((e) => e.properties.hari === newHari);
  let tempo = cloneId[oldId];
  cloneId[oldId] = {
    ...polygonList[newId],
    properties: { ...polygonList[newId].properties, hari: newHari },
  };
  cloneId[newId] = {
    ...tempo,
    properties: { ...tempo.properties, hari: tempo.properties.hari },
  };
  return cloneId;
};
