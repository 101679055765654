//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
//Personal Component
//Redux function
//Picture Asset
//General Function
class TokoLayer extends Component {
  render() {
    const { toko_list } = this.props.data;
    let content = null;
    if (toko_list.length > 0) {
      const new_features = toko_list.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      let geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      content = (
        <main>
          <Source
            key={"toko_list-src"}
            id="toko_list-src"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="toko_list-layer"
            key="toko_list-layer"
            type="circle"
            source={"toko_list-src"}
            paint={{
              "circle-color": [
                "match",
                ["get", "SYSTEM"],
                "IF",
                "#000CF3",
                "#E70000",
              ],
              "circle-radius": 5,
              "circle-stroke-width": 1,
              "circle-stroke-color": "white",
              "circle-opacity": 1,
            }}
            onLeave={this.onLeave}
            onClick={this.props.layerOnClick.bind(this, "modal_dis")}
          />
        </main>
      );
    }

    return content;
  }
}
const mapStateToProps = (state) => ({
  map: state.map,
  data: state.data,
});
export default connect(mapStateToProps, {})(TokoLayer);
