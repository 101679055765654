/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal.js";
import TextFieldGroup from "../common/TextFieldGroup.js";

/*REDUX FUNCTION*/
import {
  edit_nama_toko,
  push_salesman_to_toko_new,
  push_gagal_akuisisi,
} from "../../actions/dataActions.js";

/*PICTURE ASSET*/
import icon_success from "../../img/icon_success.png";

/*GENERAL*/

/*NON IMPORT*/

class TokoListSubmit extends Component {
  state = {
    modal_akuisisi: false,
    modal_edit: false,
    outlet_id: "",
    nama_toko: "",
    id_nabati: "",
    mode_list: "all", //all | success | failed,
    mode_submit: "success", //success | failed
    toko_selected: {},
    reset_status: true,
    failed_reason: "",
  };

  set_mode_submit = (mode_submit) => {
    this.setState({ mode_submit });
  };

  toggle_akuisisi = (toko_selected) => {
    if (toko_selected?.reset_status) {
      this.setState({ reset_status: true });
    }
    this.setState({
      modal_akuisisi: !this.state.modal_akuisisi,
      failed_reason: this.props.data?.template_list?.[0]?.text,
    });
    if (toko_selected) {
      this.setState({
        toko_selected,
        nama_toko: toko_selected?.["Nama Toko"],
        failed_reason: toko_selected?.["failed_reason"],
        id_nabati: toko_selected?.["id_nabati"],
      });
    }
    const { salesman_detail } = this.props.data;
    const kode_sales = salesman_detail?.["kd_sales_arr"]?.[0];
    const div = salesman_detail?.["divisi_arr"]?.[0];
    this.setState({ kode_sales, div });
  };

  on_set_mode = (mode_list) => {
    this.setState({ mode_list });
  };

  toggle_edit = (toko_selected) => {
    this.setState({ modal_edit: !this.state.modal_edit });
    if (toko_selected) {
      this.setState({
        outlet_id: toko_selected._id,
        nama_toko: toko_selected["Nama Toko"],
        id_nabati: toko_selected["id_nabati"],
      });
    }
  };

  on_change = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  on_submit_edit = () => {
    const { outlet_id, nama_toko, id_nabati } = this.state;
    const body = {
      outlet_id,
      nama_toko,
      id_nabati,
    };
    this.props.edit_nama_toko(body);
    this.setState({ modal_edit: false });
  };

  on_submit_akuisisi = () => {
    this.setState({ reset_status: false });
    const { hari, freq, kode_sales, div, toko_selected, id_nabati } =
      this.state;
    const outlet_id = toko_selected._id;
    const { salesman_detail } = this.props.data;
    const salesman_id = salesman_detail?._id;
    const salesman_nik = salesman_detail?.NIK;
    const salesman_name = salesman_detail?.["Nama Salesman"];
    const body = {
      outlet_id,
      salesman_id,
      salesman_nik,
      NamaSales: salesman_name,
      HARIKunjungan: hari,
      hari_new: hari,
      FreqVisit: freq,
      KodeSales: kode_sales,
      div,
      id_nabati,
    };
    this.props.push_salesman_to_toko_new(body);
  };

  on_submit_gagal = () => {
    this.setState({ reset_status: false });
    const {
      // hari, freq, kode_sales, div,
      toko_selected,
      failed_reason,
      id_nabati,
      nama_toko,
    } = this.state;
    const outlet_id = toko_selected._id;
    const { salesman_detail } = this.props.data;
    const salesman_id = salesman_detail?._id;
    const salesman_nik = salesman_detail?.NIK;
    const salesman_name = salesman_detail?.["Nama Salesman"];
    const body = {
      outlet_id,
      failed_reason,
      id_nabati,
      nama_toko,
      salesman_id,
      salesman_nik,
      NamaSales: salesman_name,

      // HARIKunjungan: hari,
      // hari_new: hari,
      // FreqVisit: freq,
      // KodeSales: kode_sales,
      // div,
    };
    this.props.push_gagal_akuisisi(body);
  };

  render() {
    //local storage

    //local state
    const {
      modal_edit,
      nama_toko,
      id_nabati,
      mode_list,
      reset_status,
      toko_selected,
      hari,
      freq,
      kode_sales,
      div,
      modal_akuisisi,
      mode_submit,
      failed_reason,
    } = this.state;

    //global props
    const {
      toko_list_submitted,
      loading_status,
      loading_item,
      salesman_detail,
    } = this.props.data;

    //content
    let toko_list_final = [];
    if (mode_list === "all") {
      toko_list_final = toko_list_submitted;
    } else if (mode_list === "success") {
      toko_list_final = toko_list_submitted.filter(
        (t) => t.acquisition_status === "success"
      );
    } else if (mode_list === "failed") {
      toko_list_final = toko_list_submitted.filter(
        (t) => t.acquisition_status === "failed"
      );
    }
    const toggle_list_content = (
      <main className="text_center mb-5">
        <button
          onClick={this.on_set_mode.bind(this, "all")}
          className="button ml-1"
          id={mode_list === "all" ? "green" : "grey"}
        >
          Semua
        </button>
        <button
          onClick={this.on_set_mode.bind(this, "success")}
          className="button ml-1"
          id={mode_list === "success" ? "green" : "grey"}
        >
          Akusisi
        </button>
        <button
          onClick={this.on_set_mode.bind(this, "failed")}
          className="button ml-1"
          id={mode_list === "failed" ? "green" : "grey"}
        >
          Tutup/tunda
        </button>
      </main>
    );
    const content = (
      <main>
        <h1 className="text_bold">
          Toko yang sudah kamu akuisisi / laporkan tutup
        </h1>
        {toggle_list_content}
        {toko_list_final.map((toko, idx) => {
          const long_toko = toko?.location?.coordinates?.[0];
          const lat_toko = toko?.location?.coordinates?.[1];
          let status_content = (
            <section className="mt-3">
              <div className="button" id="green">
                Sukses akuisisi
              </div>
            </section>
          );
          if (toko.acquisition_status === "failed") {
            status_content = (
              <section className="mt-3">
                <div className="button" id="red">
                  Tutup/tunda
                </div>
                <div className="text_inferior">{toko.failed_reason}</div>
              </section>
            );
          }
          return (
            <main className="main_container" key={idx}>
              <section className="text_bold">{toko["Nama Toko"]}</section>
              <section>
                <button
                  onClick={this.toggle_edit.bind(this, toko)}
                  className="button"
                  id="green"
                >
                  Edit
                </button>
              </section>
              <section style={{ marginBottom: "10px" }}>
                {toko["id_nabati"] && (
                  <section style={{ marginBottom: "10px" }}>
                    <p className="text_inferior">id_nabati</p>
                    <p className="text_medium">{toko["id_nabati"]}</p>
                  </section>
                )}
                <p className="text_inferior">Alamat</p>
                <p className="text_medium">{toko["ALAMAT"]}</p>
                <p className="text_medium">{toko["KELURAHAN"]}</p>
                <p className="text_medium">{toko["KECAMATAN"]}</p>
                <p className="text_medium">{toko["KABUPATEN"]}</p>
                <p className="text_medium">{toko["PROPINSI"]}</p>
                <p className="text_medium">{toko["KDPOS"]}</p>
              </section>
              {toko["SEGMENT"] && (
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Segment</p>
                  <p className="text_medium">{toko["SEGMENT"]}</p>
                </section>
              )}
              {toko["OMSET"] && (
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Omset</p>
                  <p className="text_medium">{toko["OMSET"]}</p>
                </section>
              )}
              <section style={{ marginBottom: "10px" }}>
                <p className="text_inferior">Competitor</p>
                <p className="text_medium">{toko["SYSTEM"]}</p>
              </section>
              <a
                className="button"
                id="blue"
                href={`https://www.google.com/maps/dir//${lat_toko},${long_toko}/`}
              >
                Rute gmaps
              </a>
              {status_content}
              <button
                className="button"
                id="green"
                onClick={this.toggle_akuisisi.bind(this, toko)}
              >
                Update kondisi
              </button>
            </main>
          );
        })}
      </main>
    );
    let button_content = (
      <button onClick={this.on_submit_edit} className="button" id="green">
        Submit edit
      </button>
    );
    if (loading_status && loading_item === "edit_nama_toko") {
      button_content = (
        <button className="button" id="grey">
          Mengirim...
        </button>
      );
    }
    const modal_edit_content = modal_edit && (
      <Modal modalSize="small" isOpen={modal_edit} onClose={this.toggle_edit}>
        <div className="box-body">
          <p className="text_bold text_center mb-10">
            Edit nama & id toko baru
          </p>
          <TextFieldGroup
            type="text"
            placeholder="Nama toko"
            info="Ganti jika nama toko telah berganti ketika dikunjungi"
            name="nama_toko"
            id="nama_toko"
            value={nama_toko}
            onChange={this.on_change}
          />
          <TextFieldGroup
            type="text"
            placeholder="ID Toko baru setelah diakuisisi"
            info="Ganti id toko sesuai aturan id nabati"
            name="id_nabati"
            id="id_nabati"
            value={id_nabati}
            onChange={this.on_change}
          />
          <section className="text_center mt-5">{button_content}</section>
        </div>
      </Modal>
    );
    let pilih_kode_sales_content;
    let pilih_divisi_content;
    if (loading_status && loading_item === "get_salesman_detail") {
    } else {
      if (salesman_detail?.["kd_sales_arr"]?.length > 0) {
        const kode_sales_array = salesman_detail["kd_sales_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_kode_sales_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih kode sales"
            name="kode_sales"
            id="kode_sales"
            value={kode_sales}
            onChange={this.on_change}
            list_selection_object={kode_sales_array}
            name_list="name"
            value_list="value"
          />
        );
      } else {
        pilih_kode_sales_content = (
          <section>
            Kamu belum memiliki kode sales, klik + untuk menambahkan
          </section>
        );
      }
      if (salesman_detail?.["divisi_arr"]?.length > 0) {
        const div_array = salesman_detail["divisi_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_divisi_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih divisi"
            name="div"
            id="div"
            value={div}
            onChange={this.on_change}
            list_selection_object={div_array}
            name_list="name"
            value_list="value"
          />
        );
      } else {
        pilih_divisi_content = (
          <main>Kamu belum divisi, tambahkan divisi di sini (coming soon)</main>
        );
      }
    }
    const hari_array = [
      {
        value: "R01",
        name: "R01",
      },
      {
        value: "R02",
        name: "R02",
      },
      {
        value: "R03",
        name: "R03",
      },
      {
        value: "R04",
        name: "R04",
      },
      {
        value: "R05",
        name: "R05",
      },
      {
        value: "R06",
        name: "R06",
      },
    ];
    //M2C13    M2C24;
    const freq_array = [
      {
        value: "M2C13",
        name: "Ganjil (M2C13)",
      },
      {
        value: "M2C24",
        name: "Genap (M2C24)",
      },
    ];
    let kunjungan_arr = toko_selected?.kunjungan_arr
      ? toko_selected?.kunjungan_arr
      : [];
    let salesman_nik_array = kunjungan_arr.map((k) => k.salesman_nik);
    let button_submit_akuisisi = (
      <button onClick={this.on_submit_akuisisi} className="button" id="green">
        Submit akuisisi
      </button>
    );
    if (loading_status && loading_item === "push_salesman_to_toko") {
      button_submit_akuisisi = (
        <div className="button" id="grey">
          Mengirim akuisisi...
        </div>
      );
    } else if (
      salesman_nik_array.includes(salesman_detail.NIK) &&
      !reset_status
    ) {
      button_submit_akuisisi = (
        <section>
          <img
            alt="success"
            src={icon_success}
            width="100px"
            style={{ margin: "auto" }}
          />
          <div className="text_bold">Berhasil submit akuisisi</div>
          <br />
          <button
            className="button mt-2"
            id="blue"
            onClick={this.toggle_submitted_toko}
          >
            Lihat toko-toko akusisi
          </button>
        </section>
      );
    }
    let button_submit_gagal = (
      <button onClick={this.on_submit_gagal} className="button" id="green">
        Submit alasan tutup/tunda
      </button>
    );
    if (loading_status && loading_item === "push_gagal_akuisisi") {
      button_submit_gagal = (
        <div className="button" id="grey">
          Mengirim gagal akuisisi...
        </div>
      );
    } else if (
      salesman_nik_array.includes(salesman_detail.NIK) &&
      !reset_status
    ) {
      button_submit_gagal = (
        <section>
          <img
            alt="success"
            src={icon_success}
            width="100px"
            style={{ margin: "auto" }}
          />
          <div className="text_bold">Berhasil submit gagal/tunda</div>
          <br />
          <button
            className="button mt-2"
            id="blue"
            onClick={this.toggle_submitted_toko}
          >
            Lihat pelaporan toko-toko
          </button>
        </section>
      );
    }

    const modal_akuisisi_content = modal_akuisisi && (
      <Modal
        modalSize="large"
        isOpen={modal_akuisisi}
        onClose={this.toggle_akuisisi}
      >
        <div className="box-body" style={{ minHeight: "70vh" }}>
          <section className="text_bold text_center mb-5">
            <p className="mb-5">Update kondisi</p>
            <section>
              <button
                onClick={this.set_mode_submit.bind(this, "success")}
                className="button mr-3"
                id={mode_submit === "success" ? "green" : "grey"}
              >
                Berhasil
              </button>
              <button
                onClick={this.set_mode_submit.bind(this, "failed")}
                className="button"
                id={mode_submit === "failed" ? "green" : "grey"}
              >
                Tunda/tutup
              </button>
            </section>
          </section>
          <main className="main_container mb-5">
            <section className="text_bold">
              {toko_selected["Nama Toko"]}
            </section>
            <section>
              <p className="text_inferior">Alamat</p>
              <p className="text_medium">{toko_selected["ALAMAT"]}</p>
              <p className="text_medium">{toko_selected["KELURAHAN"]}</p>
              <p className="text_medium">{toko_selected["KECAMATAN"]}</p>
              <p className="text_medium">{toko_selected["KABUPATEN"]}</p>
              <p className="text_medium">{toko_selected["PROPINSI"]}</p>
              <p className="text_medium">{toko_selected["KDPOS"]}</p>
            </section>
          </main>
          {mode_submit === "success" ? (
            // mode success input form akuisisi
            <main>
              <main>
                <TextFieldGroup
                  type="selection"
                  placeholder="Pilih hari kunjungan"
                  name="hari"
                  id="hari"
                  value={hari}
                  onChange={this.on_change}
                  list_selection_object={hari_array}
                  name_list="name"
                  value_list="value"
                />
                <TextFieldGroup
                  type="selection"
                  placeholder="Pilih frekuensi kunjungan"
                  name="freq"
                  id="freq"
                  value={freq}
                  onChange={this.on_change}
                  list_selection_object={freq_array}
                  name_list="name"
                  value_list="value"
                />
                {pilih_kode_sales_content}
                {pilih_divisi_content}
                <TextFieldGroup
                  type="text"
                  placeholder="Isikan ID toko PMA"
                  name="id_nabati"
                  id="id_nabati"
                  value={id_nabati}
                  onChange={this.on_change}
                />
              </main>
              <section className="text_center">
                {button_submit_akuisisi}
              </section>
            </main>
          ) : (
            //mode gagal pilih alasan gagal akuisisi
            <main>
              <TextFieldGroup
                type="selection"
                placeholder="Pilih alasan tunda/tutup"
                name="failed_reason"
                id="failed_reason"
                value={failed_reason}
                onChange={this.on_change}
                list_selection_object={this.props.data.template_list}
                name_list="text"
                value_list="text"
              />
              {failed_reason === "Toko Sudah Terdaftar di PMA" && (
                <main>
                  <TextFieldGroup
                    type="text"
                    placeholder="Ganti nama toko jika toko telah mengganti nama"
                    name="nama_toko"
                    id="nama_toko"
                    value={nama_toko}
                    onChange={this.on_change}
                  />
                  <TextFieldGroup
                    type="text"
                    placeholder="Isikan ID toko PMA"
                    name="id_nabati"
                    id="id_nabati"
                    value={id_nabati}
                    onChange={this.on_change}
                  />
                </main>
              )}
              <section className="text_center">{button_submit_gagal}</section>
            </main>
          )}
        </div>
      </Modal>
    );
    return (
      <main>
        {content}
        {modal_edit_content}
        {modal_akuisisi_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  edit_nama_toko,
  push_salesman_to_toko_new,
  push_gagal_akuisisi,
})(TokoListSubmit);
