/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal.js";
import TextFieldGroup from "../common/TextFieldGroup.js";
import TokoListSubmit from "./TokoListSubmit.js";
import PushKodeSales from "./PushKodeSales.js";
import PushDivisi from "./PushDivisi.js";
import MERCHANDISING_BOX from "../merchandising/MERCHANDISING_BOX.jsx";

/*REDUX FUNCTION*/
import {
  set_menu,
  get_competitor,
  push_salesman_to_toko_new,
  push_gagal_akuisisi,
  set_toko_list,
  get_competitor_by_kecamatan,
  get_detail_depo_by_name,
  get_toko_by_multi_rayon,
  edit_kd_sales_arr,
  edit_divisi_arr,
  get_competitor_code_list,
  get_competitor_list_by_area,
  set_value,
} from "../../actions/dataActions";

/*PICTURE ASSET*/
import icon_success from "../../img/icon_success.png";

/*GENERAL FUNCTION & DATA*/
import sort_array from "../../validation/sort_array";

/*NON IMPORT*/

class Main extends Component {
  state = {
    modal_radius: false,
    modal_submitted_toko: false,
    modal_urutkan: false,
    modal_kec: false,
    modal_akuisisi: false,
    modal_kode_sales: false,
    modal_divisi: false,
    reset_status: false,
    modal_delete_kode_sales: false,
    modal_edit_kode_sales: false,
    modal_delete_divisi: false,
    modal_edit_divisi: false,
    kode_sales_idx: 0,
    divisi_idx: 0,
    kode_sales_edit: "",
    divisi_edit: "",
    radius: 1,
    urut_param: "",
    alert_radius_max: false,
    alert_radius_min: false,
    radius_min: 1,
    radius_max: 30,
    toko_selected: {},
    hari: "R01",
    freq: "M2C13",
    kode_sales: "",
    div: "",
    mode_get: "depo", //depo | radius | kec | rayon
    mode_submit: "success", //success | failed
    failed_reason: "",
    template_list: [],
    id_nabati: "",
    nama_toko: "",
    name_param: "",
    code: "",
    toko_list_state: [],
  };

  componentDidMount() {
    this.props.get_competitor_code_list();
    const { toko_list } = this.props.data;
    if (toko_list.length === 0) {
      this.on_get_competitor_list_by_area();
    }
  }

  reset_kecamatan = () => {
    let { toko_list_backup } = this.props.data;
    this.props.set_value({
      key: "toko_list",
      value: toko_list_backup,
    });
    this.props.set_value({
      key: "kecamatan_selected",
      value: "All",
    });
    this.setState({ modal_kec: false });
  };

  filter_kecamatan = (KECAMATAN) => {
    let { toko_list_backup } = this.props.data;
    const toko_list = toko_list_backup.filter((t) => t.KECAMATAN === KECAMATAN);
    this.props.set_value({
      key: "toko_list",
      value: toko_list,
    });
    this.props.set_value({
      key: "kecamatan_selected",
      value: KECAMATAN,
    });
    this.setState({ modal_kec: false });
  };

  on_get_competitor_list_by_area = () => {
    const { salesman_detail } = this.props.data;
    const params = {
      depo: salesman_detail.Depo,
    };
    this.props.get_competitor_list_by_area(params);
  };

  on_search(e) {
    e.preventDefault();
    const { name_param } = this.state;
    let toko_list_state = this.props.data.toko_list.filter(
      (t) =>
        t?.["Nama Toko"]?.toUpperCase()?.includes(name_param.toUpperCase()) ||
        t?.["ID Outlet"]?.toUpperCase()?.includes(name_param.toUpperCase())
    );
    this.setState({ toko_list_state });
  }

  submit_delete_kode_sales = () => {
    const { kode_sales_idx } = this.state;
    const { salesman_detail } = this.props.data;
    let kd_sales_arr = salesman_detail?.kd_sales_arr
      ? salesman_detail?.kd_sales_arr
      : [];
    kd_sales_arr.splice(kode_sales_idx, 1);
    const kd_sales_arr_string = kd_sales_arr.join(",");
    const token = this.props.params.token;
    const body = {
      kd_sales_arr: kd_sales_arr_string,
      token,
      kd_sales_arr_ori: kd_sales_arr,
    };
    this.props.edit_kd_sales_arr(body);
    this.toggle_delete_kode_sales();
  };

  submit_edit_kode_sales = () => {
    const { kode_sales_idx, kode_sales_edit } = this.state;
    const { salesman_detail } = this.props.data;
    let kd_sales_arr = salesman_detail?.kd_sales_arr
      ? salesman_detail?.kd_sales_arr
      : [];
    kd_sales_arr[kode_sales_idx] = kode_sales_edit;
    const kd_sales_arr_string = kd_sales_arr.join(",");
    const token = this.props.params.token;
    const body = {
      kd_sales_arr: kd_sales_arr_string,
      token,
      kd_sales_arr_ori: kd_sales_arr,
    };
    this.props.edit_kd_sales_arr(body);
    this.toggle_edit_kode_sales();
  };

  submit_delete_divisi = () => {
    const { divisi_idx } = this.state;
    const { salesman_detail } = this.props.data;
    let divisi_arr = salesman_detail?.divisi_arr
      ? salesman_detail?.divisi_arr
      : [];
    divisi_arr.splice(divisi_idx, 1);
    const divisi_arr_string = divisi_arr.join(",");
    const token = this.props.params.token;
    const body = {
      divisi_arr: divisi_arr_string,
      token,
      divisi_arr_ori: divisi_arr,
    };
    this.props.edit_divisi_arr(body);
    this.toggle_delete_divisi();
  };

  submit_edit_divisi = () => {
    const { divisi_idx, divisi_edit } = this.state;
    const { salesman_detail } = this.props.data;
    let divisi_arr = salesman_detail?.divisi_arr
      ? salesman_detail?.divisi_arr
      : [];
    divisi_arr[divisi_idx] = divisi_edit;
    const divisi_arr_string = divisi_arr.join(",");
    const token = this.props.params.token;
    const body = {
      divisi_arr: divisi_arr_string,
      token,
      divisi_arr_ori: divisi_arr,
    };
    this.props.edit_divisi_arr(body);
    this.toggle_edit_divisi();
  };

  toggle_edit_kode_sales = (kode_sales_idx, kode_sales_edit) => {
    this.setState({
      modal_edit_kode_sales: !this.state.modal_edit_kode_sales,
    });
    if (kode_sales_edit !== undefined) {
      this.setState({ kode_sales_idx, kode_sales_edit });
    }
  };

  toggle_delete_kode_sales = (kode_sales_idx, kode_sales_edit) => {
    this.setState({
      modal_delete_kode_sales: !this.state.modal_delete_kode_sales,
    });
    if (kode_sales_edit !== undefined) {
      this.setState({ kode_sales_idx, kode_sales_edit });
    }
  };

  toggle_edit_divisi = (divisi_idx, divisi_edit) => {
    this.setState({ modal_edit_divisi: !this.state.modal_edit_divisi });
    if (divisi_edit !== undefined) {
      this.setState({ divisi_idx, divisi_edit });
    }
  };

  toggle_delete_divisi = (divisi_idx, divisi_edit) => {
    this.setState({ modal_delete_divisi: !this.state.modal_delete_divisi });
    if (divisi_edit !== undefined) {
      this.setState({ divisi_idx, divisi_edit });
    }
  };

  on_get_toko_by_multi_rayon = () => {
    const { salesman_detail } = this.props.data;
    const rayon_id_list = salesman_detail?.rayon_id_list;
    const rayon_id_array = rayon_id_list.join(",");
    this.setState({ mode_get: "rayon" });
    const params = { rayon_id_array };
    this.props.get_toko_by_multi_rayon(params);
  };

  toggle_divisi = () => {
    this.setState({ modal_divisi: !this.state.modal_divisi });
  };

  toggle_kode_sales = () => {
    this.setState({ modal_kode_sales: !this.state.modal_kode_sales });
  };

  set_mode_submit = (mode_submit) => {
    this.setState({ mode_submit });
  };

  toggle_kec = () => {
    this.setState({ modal_kec: !this.state.modal_kec, mode_get: "kec" });
    const { salesman_detail } = this.props.data;
    const params = {
      depo_name: salesman_detail.Depo,
    };
    this.props.get_detail_depo_by_name(params);
  };

  on_get_competitor_by_kecamatan = (KECAMATAN) => {
    const { depo_detail } = this.props.data;

    const KABUPATEN_array = depo_detail?.KABUPATEN_array
      ? depo_detail?.KABUPATEN_array
      : [];

    const KABUPATEN_array_string = KABUPATEN_array.join();

    const params = { KECAMATAN, KABUPATEN_array_string };
    this.props.get_competitor_by_kecamatan(params);
    this.setState({ modal_kec: false });
  };

  on_urut_jarak = () => {
    this.setState({ modal_urutkan: false, urut_param: "jarak" });
    function calc_crow(lat1, lon1, lat2, lon2) {
      const R = 6371; // km
      const dLat = to_rad(lat2 - lat1);
      const dLon = to_rad(lon2 - lon1);
      const lat_1 = to_rad(lat1);
      const lat_2 = to_rad(lat2);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat_1) *
          Math.cos(lat_2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return d;
    }
    function to_rad(Value) {
      return (Value * Math.PI) / 180;
    }
    let { salesman_detail, toko_list } = this.props.data;
    const long_salesman = salesman_detail?.location?.coordinates?.[0];
    const lat_salesman = salesman_detail?.location?.coordinates?.[1];

    //urutkan props
    toko_list = toko_list.map((toko) => {
      const long_toko = toko?.location?.coordinates?.[0];
      const lat_toko = toko?.location?.coordinates?.[1];
      const distance = calc_crow(
        lat_salesman,
        long_salesman,
        lat_toko,
        long_toko
      );
      return { ...toko, distance };
    });
    toko_list = sort_array(toko_list, "distance", true);
    this.props.set_toko_list(toko_list);

    //urutkan state
    let { toko_list_state } = this.state;
    toko_list_state = toko_list_state.map((toko) => {
      const long_toko = toko?.location?.coordinates?.[0];
      const lat_toko = toko?.location?.coordinates?.[1];
      const distance = calc_crow(
        lat_salesman,
        long_salesman,
        lat_toko,
        long_toko
      );
      return { ...toko, distance };
    });
    toko_list_state = sort_array(toko_list_state, "distance", true);
    this.setState({ toko_list_state });
  };

  on_urut_omset = () => {
    //urutkan props
    this.setState({ modal_urutkan: false, urut_param: "omset" });
    let { toko_list } = this.props.data;
    toko_list = sort_array(toko_list, "OMSET", false);
    this.props.set_toko_list(toko_list);

    //urutkan state
    let { toko_list_state } = this.state;
    toko_list_state = sort_array(toko_list_state, "OMSET", false);
    this.setState({ toko_list_state });
  };

  on_urut_nama = () => {
    //urutkan props
    this.setState({ modal_urutkan: false, urut_param: "nama" });
    let { toko_list } = this.props.data;
    toko_list = sort_array(toko_list, "Nama Toko", true);
    this.props.set_toko_list(toko_list);

    //urutkan state
    let { toko_list_state } = this.state;
    toko_list_state = sort_array(toko_list_state, "Nama Toko", true);
    this.setState({ toko_list_state });
  };

  toggle_urutkan = () => {
    this.setState({ modal_urutkan: !this.state.modal_urutkan });
  };

  toggle_submitted_toko = () => {
    this.setState({ modal_submitted_toko: !this.state.modal_submitted_toko });
  };

  on_submit_akuisisi = () => {
    this.setState({ reset_status: false });
    const { nama_toko, hari, freq, kode_sales, div, toko_selected, id_nabati } =
      this.state;
    const outlet_id = toko_selected._id;
    const { salesman_detail } = this.props.data;
    const salesman_id = salesman_detail?._id;
    const salesman_nik = salesman_detail?.NIK;
    const salesman_name = salesman_detail?.["Nama Salesman"];
    const body = {
      nama_toko,
      outlet_id,
      salesman_id,
      salesman_nik,
      NamaSales: salesman_name,
      HARIKunjungan: hari,
      hari_new: hari,
      FreqVisit: freq,
      KodeSales: kode_sales,
      div,
      id_nabati,
    };
    this.props.push_salesman_to_toko_new(body);
  };

  on_submit_gagal = () => {
    this.setState({ reset_status: false });
    const {
      // hari, freq, kode_sales, div,
      toko_selected,
      failed_reason,
      id_nabati,
      nama_toko,
    } = this.state;
    const outlet_id = toko_selected._id;
    const { salesman_detail } = this.props.data;
    const salesman_id = salesman_detail?._id;
    const salesman_nik = salesman_detail?.NIK;
    const salesman_name = salesman_detail?.["Nama Salesman"];
    const body = {
      outlet_id,
      failed_reason,
      id_nabati,
      nama_toko,
      salesman_id,
      salesman_nik,
      NamaSales: salesman_name,

      // HARIKunjungan: hari,
      // hari_new: hari,
      // FreqVisit: freq,
      // KodeSales: kode_sales,
      // div,
    };
    this.props.push_gagal_akuisisi(body);
  };

  on_change = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
    if (name === "code") {
      let toko_list_state = this.props.data.toko_list.filter(
        (t) => t.SYSTEM === value
      );
      this.setState({ toko_list_state });
    }
  };

  toggle_akuisisi = (toko_selected) => {
    if (toko_selected?.reset_status) {
      this.setState({ reset_status: true });
    }
    this.setState({
      modal_akuisisi: !this.state.modal_akuisisi,
      failed_reason: this.props.data?.template_list?.[0]?.text,
    });
    if (toko_selected) {
      this.setState({ toko_selected, nama_toko: toko_selected?.["Nama Toko"] });
    }
    const { salesman_detail } = this.props.data;
    const kode_sales = salesman_detail?.["kd_sales_arr"]?.[0];
    const div = salesman_detail?.["divisi_arr"]?.[0];
    this.setState({ kode_sales, div });
  };

  on_get_competitor = () => {
    let params = this.props.params;
    const { radius } = this.state;
    params = { ...params, radius };
    this.props.get_competitor(params);
    this.setState({ modal_radius: false, mode_get: "radius" });
  };

  on_plus_radius = () => {
    let { radius, radius_max } = this.state;
    if (radius < radius_max) {
      this.setState({ radius: radius + 1, alert_radius_min: false });
    } else {
      this.setState({ alert_radius_max: true });
    }
  };

  on_minus_radius = () => {
    let { radius, radius_min } = this.state;
    if (radius > radius_min) {
      this.setState({ radius: radius - 1, alert_radius_max: false });
    } else {
      this.setState({ alert_radius_min: true });
    }
  };

  toggle_radius = () => {
    this.setState({ modal_radius: !this.state.modal_radius });
  };

  on_set_menu = (menu) => {
    this.props.set_menu(menu);
  };

  render() {
    //local storage

    //local state
    const {
      modal_radius,
      modal_submitted_toko,
      modal_urutkan,
      modal_kec,
      modal_akuisisi,
      modal_kode_sales,
      modal_divisi,
      reset_status,
      modal_delete_kode_sales,
      modal_edit_kode_sales,
      modal_delete_divisi,
      modal_edit_divisi,
      kode_sales_edit,
      divisi_edit,
      radius,
      alert_radius_max,
      alert_radius_min,
      radius_max,
      radius_min,
      toko_selected,
      hari,
      freq,
      kode_sales,
      div,
      mode_get,
      mode_submit,
      failed_reason,
      id_nabati,
      nama_toko,
      name_param,
      code,
    } = this.state;

    //global props
    const {
      toko_list,
      toko_list_submitted,
      salesman_detail,
      loading_status,
      loading_item,
      version,
      competitor_code_list,
      kecamatan_list,
      depo_detail,
    } = this.props.data;

    //content
    const merchandising_status = salesman_detail?.merchandising_status;
    let toko_list_state;

    if (name_param || code) {
      toko_list_state = this.state.toko_list_state;
    } else {
      toko_list_state = toko_list;
    }

    let salesman_content;
    let pilih_kode_sales_content;
    let pilih_divisi_content;

    if (loading_status && loading_item === "get_salesman_detail") {
      salesman_content = (
        <main className="main_container">Memuat data salesman...</main>
      );
    } else {
      let kode_sales_content;
      if (salesman_detail?.["kd_sales_arr"]?.length > 0) {
        kode_sales_content = (
          <table style={{ tableLayout: "fixed" }}>
            <tbody>
              {salesman_detail?.["kd_sales_arr"].map((kode, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}. </td>
                    <td>{kode}</td>
                    <td>
                      <button
                        className="button_small mr-3"
                        id="blue"
                        onClick={this.toggle_edit_kode_sales.bind(
                          this,
                          idx,
                          kode
                        )}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button_small"
                        id="red"
                        onClick={this.toggle_delete_kode_sales.bind(
                          this,
                          idx,
                          kode
                        )}
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
        const kode_sales_array = salesman_detail["kd_sales_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_kode_sales_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih kode sales"
            name="kode_sales"
            id="kode_sales"
            value={kode_sales}
            onChange={this.on_change}
            list_selection_object={kode_sales_array}
            name_list="name"
            value_list="value"
          />
        );
      } else {
        kode_sales_content = (
          <section>
            Kamu belum memiliki kode sales, klik Tambah kode sales untuk
            menambahkan
          </section>
        );
        pilih_kode_sales_content = (
          <section>
            Kamu belum memiliki kode sales, klik Tambah divisi untuk menambahkan
          </section>
        );
      }
      let divisi_content;
      if (salesman_detail?.["divisi_arr"]?.length > 0) {
        divisi_content = (
          <table style={{ tableLayout: "fixed" }}>
            <tbody>
              {salesman_detail?.["divisi_arr"].map((kode, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}. </td>
                    <td>{kode}</td>
                    <td>
                      <button
                        className="button_small mr-3"
                        id="blue"
                        onClick={this.toggle_edit_divisi.bind(this, idx, kode)}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button_small"
                        id="red"
                        onClick={this.toggle_delete_divisi.bind(
                          this,
                          idx,
                          kode
                        )}
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
        const div_array = salesman_detail["divisi_arr"].map((e) => {
          return { value: e, name: e };
        });
        pilih_divisi_content = (
          <TextFieldGroup
            type="selection"
            placeholder="Pilih divisi"
            name="div"
            id="div"
            value={div}
            onChange={this.on_change}
            list_selection_object={div_array}
            name_list="name"
            value_list="value"
          />
        );
      } else {
        divisi_content = (
          <section>
            Kamu belum memiliki divisi, masukkan di sini (coming soon)
          </section>
        );
        pilih_divisi_content = (
          <main>Kamu belum divisi, tambahkan divisi di sini (coming soon)</main>
        );
      }

      const toko_list_submitted_content = (
        <main>
          <p className="text_bold">Performa akuisisi</p>
          <section>
            <b>{toko_list_submitted.length}</b> toko sudah diakuisisi /
            dilaporkan tutup
          </section>
          <button
            className="button mb-2"
            id="green"
            onClick={this.toggle_submitted_toko}
          >
            Lihat
          </button>
        </main>
      );

      salesman_content = (
        <main>
          <section className="main_container">
            <p className="text_bold">Detail salesman</p>
            <table className="table_lite full_width">
              <tbody>
                <tr>
                  <td>Salesman name</td>
                  <td>{salesman_detail?.["Nama Only"]}</td>
                </tr>
                <tr>
                  <td>Depo</td>
                  <td>{salesman_detail?.["Depo"]}</td>
                </tr>
                <tr>
                  <td>Plan id depo</td>
                  <td>{depo_detail?.["plan_id"]}</td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <p>Kode sales</p>
                    <button
                      className="button_small"
                      id="green"
                      onClick={this.toggle_kode_sales}
                    >
                      Tambah kode sales
                    </button>
                    <br />
                    <br />
                    {kode_sales_content}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p>Divisi</p>
                    <button
                      className="button_small"
                      id="green"
                      onClick={this.toggle_divisi}
                    >
                      Tambah divisi
                    </button>
                    <br />
                    <br />
                    {divisi_content}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {merchandising_status && <MERCHANDISING_BOX />}

          <section className="main_container">
            {toko_list_submitted_content}
            <button
              className="button"
              id="green"
              onClick={this.on_set_menu.bind(this, "map")}
            >
              Mode peta
            </button>
          </section>
        </main>
      );
    }

    const filter_content = (
      <main>
        <section className="main_container">
          <p>
            Jumlah toko kompetitor: <b>{toko_list_state.length}.</b>
          </p>
          {mode_get === "radius" ? (
            <p>
              Radius <b>{radius} km </b>dari titik Depo kamu.
            </p>
          ) : (
            <p>{mode_get}</p>
          )}
          <button
            className="button mr-1 mb-1"
            id={mode_get === "depo" ? "green" : "grey"}
            onClick={this.on_get_competitor_list_by_area}
          >
            Mode depo
          </button>
          <button
            className="button mr-1 mb-1"
            id={mode_get === "radius" ? "green" : "grey"}
            onClick={this.toggle_radius}
          >
            Mode radius
          </button>
          <button
            className="button mr-1 mb-1"
            id={mode_get === "kec" ? "green" : "grey"}
            onClick={this.toggle_kec}
          >
            Mode kecamatan
          </button>
          <button
            className="button mr-1 mb-1"
            id={mode_get === "rayon" ? "green" : "grey"}
            onClick={this.on_get_toko_by_multi_rayon}
          >
            Mode rayon
          </button>
          <p>Kecamatan selected: {this.props.data.kecamatan_selected}</p>
        </section>
        <section>
          <p className="text_inferior">Cari toko dengan nama atau id</p>
          <form onSubmit={this.on_search.bind(this)}>
            <input
              placeholder="Cari toko dengan nama atau id"
              id="name_param"
              name="name_param"
              value={name_param}
              onChange={this.on_change}
              className="mr-2 mb-2"
            />
            <button type="submit" className="button" id="green">
              Cari
            </button>
          </form>
          <p className="text_inferior">Filter by kode perusahaan</p>
          <select
            onChange={this.on_change}
            name="code"
            id="code"
            value={code}
            className="mb-2"
          >
            <option value="">Semua</option>
            {competitor_code_list.map((e, idx) => {
              return (
                <option key={idx} value={e?.text}>
                  {e?.text}
                </option>
              );
            })}
          </select>
        </section>
      </main>
    );

    let list_content;
    if (loading_status && loading_item === "get_competitor") {
      list_content = (
        <main className="main_container">Memuat data toko kompetitor...</main>
      );
    } else if (toko_list?.length === 0) {
      // list_content = <main className="main_container">Tidak ada toko</main>;
      list_content = (
        <main className="main_container">Memuat data toko kompetitor...</main>
      );
    } else {
      list_content = (
        <main>
          {toko_list_state.map((toko, idx) => {
            let distance_content;
            if (toko.distance !== undefined) {
              const distance = parseFloat(toko.distance).toFixed(2);
              distance_content = (
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Jarak</p>
                  <p className="text_medium">{distance} km</p>
                </section>
              );
            }
            const long_toko = toko?.location?.coordinates?.[0];
            const lat_toko = toko?.location?.coordinates?.[1];
            let kunjungan_arr = toko?.kunjungan_arr ? toko?.kunjungan_arr : [];
            let salesman_nik_array = kunjungan_arr.map((k) => k.salesman_nik);
            let button_content;
            if (
              toko_selected._id === toko._id &&
              loading_status &&
              loading_item === "push_salesman_to_toko"
            ) {
              button_content = (
                <div className="button mr-2 mb-2" id="grey">
                  Mengirimkan data...
                </div>
              );
            } else if (salesman_nik_array.includes(salesman_detail.NIK)) {
              button_content = (
                <div className="button mr-2 mb-2" id="grey">
                  Sudah kamu akuisisi
                </div>
              );
              if (toko.acquisition_status === "failed") {
                toko.reset_status = true;
                button_content = (
                  <section>
                    <p className="text_inferior mr-2 mb-2">
                      Sudah kamu laporkan, alasan:{" "}
                      <b className="button_small" id="red">
                        {toko.failed_reason}
                      </b>
                    </p>
                    <button
                      className="button mr-2 mb-2"
                      id="blue"
                      onClick={this.toggle_akuisisi.bind(this, toko)}
                    >
                      Update kondisi
                    </button>
                  </section>
                );
              }
            } else if (kunjungan_arr.length > 0) {
              button_content = (
                <div className="button mr-2 mb-2" id="grey">
                  Sudah diakuisisi salesman lain
                </div>
              );
              if (toko.acquisition_status === "failed") {
                toko.reset_status = true;
                button_content = (
                  <section>
                    <p className="text_inferior mr-2 mb-2">
                      Sudah dilaporkan salesman lain, alasan:{" "}
                      <b className="button_small" id="red">
                        {toko.failed_reason}
                      </b>
                    </p>
                    <button
                      className="button mr-2 mb-2"
                      id="blue"
                      onClick={this.toggle_akuisisi.bind(this, toko)}
                    >
                      Update kondisi
                    </button>
                  </section>
                );
              }
            } else {
              button_content = (
                <button
                  className="button mr-2 mb-2"
                  id="blue"
                  onClick={this.toggle_akuisisi.bind(this, toko)}
                >
                  Akuisisi toko
                </button>
              );
            }
            let segment_content;
            if (toko["SEGMENT"]) {
              segment_content = (
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Segment</p>
                  <p className="text_medium">{toko["SEGMENT"]}</p>
                </section>
              );
            }
            let omset_content;
            if (toko["OMSET"]) {
              omset_content = (
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Omset</p>
                  <p className="text_medium">
                    Rp.
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(toko["OMSET"])}
                  </p>
                </section>
              );
            }
            return (
              <main className="main_container" key={idx}>
                {/* {toko._id} */}
                <section className="text_bold">{toko["Nama Toko"]}</section>
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Alamat</p>
                  <p className="text_medium">{toko["ALAMAT"]}</p>
                  <p className="text_medium">{toko["KELURAHAN"]}</p>
                  <p className="text_medium">{toko["KECAMATAN"]}</p>
                  <p className="text_medium">{toko["KABUPATEN"]}</p>
                  {/* <p className="text_medium">{toko["PROPINSI"]}</p>
                  <p className="text_medium">{toko["KDPOS"]}</p> */}
                </section>
                {segment_content}
                {omset_content}
                <section style={{ marginBottom: "10px" }}>
                  <p className="text_inferior">Kode perusahaan</p>
                  <p className="text_medium">{toko["SYSTEM"]}</p>
                </section>
                {distance_content}
                {button_content}
                <a
                  className="button"
                  id="blue"
                  href={`https://www.google.com/maps/dir//${lat_toko},${long_toko}/`}
                >
                  Rute gmaps
                </a>
                <p className="text_inferior">{idx + 1}</p>
              </main>
            );
          })}
          <section className="text_center text_inferior">
            End of the line
          </section>
        </main>
      );
    }
    const hari_array = [
      {
        value: "R01",
        name: "R01",
      },
      {
        value: "R02",
        name: "R02",
      },
      {
        value: "R03",
        name: "R03",
      },
      {
        value: "R04",
        name: "R04",
      },
      {
        value: "R05",
        name: "R05",
      },
      {
        value: "R06",
        name: "R06",
      },
    ];
    //M2C13    M2C24;
    const freq_array = [
      {
        value: "M2C13",
        name: "Ganjil (M2C13)",
      },
      {
        value: "M2C24",
        name: "Genap (M2C24)",
      },
    ];
    let kunjungan_arr = toko_selected?.kunjungan_arr
      ? toko_selected?.kunjungan_arr
      : [];
    let salesman_nik_array = kunjungan_arr.map((k) => k.salesman_nik);
    let button_submit_akuisisi = (
      <button onClick={this.on_submit_akuisisi} className="button" id="green">
        Submit akuisisi
      </button>
    );
    if (loading_status && loading_item === "push_salesman_to_toko") {
      button_submit_akuisisi = (
        <div className="button" id="grey">
          Mengirim akuisisi...
        </div>
      );
    } else if (
      salesman_nik_array.includes(salesman_detail.NIK) &&
      !reset_status
    ) {
      button_submit_akuisisi = (
        <section>
          <img
            alt="success"
            src={icon_success}
            width="100px"
            style={{ margin: "auto" }}
          />
          <div className="text_bold">Berhasil submit akuisisi</div>
          <br />
          <button
            className="button mt-2"
            id="blue"
            onClick={this.toggle_submitted_toko}
          >
            Lihat toko-toko akusisi
          </button>
        </section>
      );
    }
    let button_submit_gagal = (
      <button onClick={this.on_submit_gagal} className="button" id="green">
        Submit alasan tutup/tunda
      </button>
    );
    if (loading_status && loading_item === "push_gagal_akuisisi") {
      button_submit_gagal = (
        <div className="button" id="grey">
          Mengirim gagal akuisisi...
        </div>
      );
    } else if (
      salesman_nik_array.includes(salesman_detail.NIK) &&
      !reset_status
    ) {
      button_submit_gagal = (
        <section>
          <img
            alt="success"
            src={icon_success}
            width="100px"
            style={{ margin: "auto" }}
          />
          <div className="text_bold">Berhasil submit gagal/tunda</div>
          <br />
          <button
            className="button mt-2"
            id="blue"
            onClick={this.toggle_submitted_toko}
          >
            Lihat pelaporan toko-toko
          </button>
        </section>
      );
    }

    const modal_akuisisi_content = modal_akuisisi && (
      <Modal
        modalSize="large"
        isOpen={modal_akuisisi}
        onClose={this.toggle_akuisisi}
      >
        <div className="box-body" style={{ minHeight: "70vh" }}>
          <section className="text_bold text_center mb-5">
            <p className="mb-5">Akuisisi toko kompetitor</p>
            <section>
              <button
                onClick={this.set_mode_submit.bind(this, "success")}
                className="button mr-3"
                id={mode_submit === "success" ? "green" : "grey"}
              >
                Berhasil
              </button>
              <button
                onClick={this.set_mode_submit.bind(this, "failed")}
                className="button"
                id={mode_submit === "failed" ? "green" : "grey"}
              >
                Tunda/tutup
              </button>
            </section>
          </section>
          <main className="main_container mb-5">
            <section className="text_bold">
              {toko_selected["Nama Toko"]}
            </section>
            <section>
              <p className="text_inferior">Alamat</p>
              <p className="text_medium">{toko_selected["ALAMAT"]}</p>
              <p className="text_medium">{toko_selected["KELURAHAN"]}</p>
              <p className="text_medium">{toko_selected["KECAMATAN"]}</p>
              <p className="text_medium">{toko_selected["KABUPATEN"]}</p>
              <p className="text_medium">{toko_selected["PROPINSI"]}</p>
              <p className="text_medium">{toko_selected["KDPOS"]}</p>
            </section>
          </main>
          {mode_submit === "success" ? (
            // mode success input form akuisisi
            <main>
              <section>
                <TextFieldGroup
                  type="text"
                  placeholder="Ganti nama toko jika toko telah mengganti nama"
                  name="nama_toko"
                  id="nama_toko"
                  value={nama_toko}
                  onChange={this.on_change}
                />
                <TextFieldGroup
                  type="selection"
                  placeholder="Pilih hari kunjungan"
                  name="hari"
                  id="hari"
                  value={hari}
                  onChange={this.on_change}
                  list_selection_object={hari_array}
                  name_list="name"
                  value_list="value"
                />
                <TextFieldGroup
                  type="selection"
                  placeholder="Pilih frekuensi kunjungan"
                  name="freq"
                  id="freq"
                  value={freq}
                  onChange={this.on_change}
                  list_selection_object={freq_array}
                  name_list="name"
                  value_list="value"
                />
                {pilih_kode_sales_content}
                {pilih_divisi_content}
                <TextFieldGroup
                  type="text"
                  placeholder="Isikan ID toko PMA"
                  name="id_nabati"
                  id="id_nabati"
                  value={id_nabati}
                  onChange={this.on_change}
                />
              </section>
              <section className="text_center">
                {button_submit_akuisisi}
              </section>
            </main>
          ) : (
            //mode gagal pilih alasan gagal akuisisi
            <main>
              <TextFieldGroup
                type="selection"
                placeholder="Pilih alasan tunda/tutup"
                name="failed_reason"
                id="failed_reason"
                value={failed_reason}
                onChange={this.on_change}
                list_selection_object={this.props.data.template_list}
                name_list="text"
                value_list="text"
              />
              {failed_reason === "Toko Sudah Terdaftar di PMA" && (
                <main>
                  <TextFieldGroup
                    type="text"
                    placeholder="Ganti nama toko jika toko telah mengganti nama"
                    name="nama_toko"
                    id="nama_toko"
                    value={nama_toko}
                    onChange={this.on_change}
                  />
                  <TextFieldGroup
                    type="text"
                    placeholder="Isikan ID toko PMA"
                    name="id_nabati"
                    id="id_nabati"
                    value={id_nabati}
                    onChange={this.on_change}
                  />
                </main>
              )}
              <section className="text_center">{button_submit_gagal}</section>
            </main>
          )}
        </div>
      </Modal>
    );
    let alert_content;
    if (alert_radius_max) {
      alert_content = <main>Maksimum radius {radius_max} km</main>;
    } else if (alert_radius_min) {
      alert_content = <main>Minimum radius {radius_min} km</main>;
    }
    const modal_submitted_toko_content = modal_submitted_toko && (
      <Modal
        modalSize="large"
        isOpen={modal_submitted_toko}
        onClose={this.toggle_submitted_toko}
      >
        <div className="box-body" style={{ minHeight: "80vh" }}>
          <TokoListSubmit />
        </div>
      </Modal>
    );
    const modal_urutkan_content = modal_urutkan && (
      <Modal
        modalSize="large"
        isOpen={modal_urutkan}
        onClose={this.toggle_urutkan}
      >
        <div
          className="box-body"
          style={{ minHeight: "50vh", paddingTop: "100px" }}
        >
          <p className="text_bold mb-10">
            Urutkan list toko kompetitor berdasarkan (pilih salah satu):
          </p>
          <button
            className="button mb-3"
            id="green"
            onClick={this.on_urut_jarak}
          >
            Jarak terdekat
          </button>
          <br />
          <button
            className="button mb-3"
            id="green"
            onClick={this.on_urut_omset}
          >
            Omset terbesar
          </button>
          <br />
          <button
            className="button mb-3"
            id="green"
            onClick={this.on_urut_nama}
          >
            Nama abjad
          </button>
        </div>
      </Modal>
    );
    const modal_radius_content = modal_radius && (
      <Modal
        modalSize="large"
        isOpen={modal_radius}
        onClose={this.toggle_radius}
      >
        <div className="box-body">
          <section
            style={{ textAlign: "center", minHeight: "50vh" }}
            className="mt-20"
          >
            <button
              className="button_big ml-1 mr-1"
              id="green"
              onClick={this.on_minus_radius}
            >
              -
            </button>
            <div
              className="button_big ml-1 mr-1"
              id="grey"
              style={{ minWidth: "50px" }}
            >
              {radius}
            </div>
            <button
              className="button_big ml-1 mr-1"
              id="green"
              onClick={this.on_plus_radius}
            >
              +
            </button>
            {alert_content}
            <br />
            <button
              onClick={this.on_get_competitor}
              className="button_big mt-5"
              id="green"
            >
              Ambil data kompetitor
            </button>
            <section className="main_container mt-5">
              Angka dalam radius dari titik depo kamu
            </section>
          </section>
        </div>
      </Modal>
    );

    const modal_kec_content = modal_kec && (
      <Modal modalSize="small" isOpen={modal_kec} onClose={this.toggle_kec}>
        <div className="box-body" style={{ minHeight: "300px" }}>
          <section className="text_bold mb-5">Choose Kecamatan</section>
          <section className="main_container">
            <p className="text_inferior">Outlet in your Depo</p>
            <p>
              <span className="text_header">{toko_list.length}</span>{" "}
              <span className="text_inferior">outlets</span>
            </p>
            <button
              className="button_small"
              id="green"
              onClick={this.reset_kecamatan}
            >
              Show all outlet depo
            </button>
          </section>
          <hr />
          <br />
          <br />
          {kecamatan_list.map((item, idx) => {
            return (
              <section key={idx} className="main_container">
                <p>{item?.KECAMATAN ? item?.KECAMATAN : "No Kec Data"}</p>
                <p>
                  <span className="text_header">{item?.toko_list_length}</span>{" "}
                  <span className="text_inferior">outlets</span>
                </p>
                <button
                  className="button_small"
                  id="green"
                  onClick={this.filter_kecamatan.bind(this, item.KECAMATAN)}
                >
                  Filter outlet
                </button>
              </section>
            );
          })}
        </div>
      </Modal>
    );

    const token = this.props.params.token;
    const modal_kode_sales_content = modal_kode_sales && (
      <Modal
        modalSize="small"
        isOpen={modal_kode_sales}
        onClose={this.toggle_kode_sales}
      >
        <div className="box-body text_center" style={{ minHeight: "300px" }}>
          <PushKodeSales token={token} />
        </div>
      </Modal>
    );

    const modal_divisi_content = modal_divisi && (
      <Modal
        modalSize="small"
        isOpen={modal_divisi}
        onClose={this.toggle_divisi}
      >
        <div className="box-body text_center" style={{ minHeight: "300px" }}>
          <PushDivisi token={token} />
        </div>
      </Modal>
    );

    const version_content = (
      <section className="button_small margin_bottom" id="grey">
        Versi: {version}
      </section>
    );

    const modal_delete_kode_sales_content = modal_delete_kode_sales && (
      <Modal
        modalSize="small"
        isOpen={modal_delete_kode_sales}
        onClose={this.toggle_delete_kode_sales}
      >
        <div className="box-body text_center">
          <section className="text_bold mb-10">
            Hapus kode sales <b>{kode_sales_edit}</b>
          </section>
          <button
            className="button mr-3 mb-3"
            id="blue"
            onClick={this.toggle_delete_kode_sales}
          >
            Batal
          </button>
          <button
            className="button"
            id="red"
            onClick={this.submit_delete_kode_sales}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    const modal_edit_kode_sales_content = modal_edit_kode_sales && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_kode_sales}
        onClose={this.toggle_edit_kode_sales}
      >
        <div className="box-body">
          <section className="mb-5">
            <p className="text_bold">Edit kode sales</p>
            <input
              id="kode_sales_edit"
              name="kode_sales_edit"
              value={kode_sales_edit}
              onChange={this.on_change}
              type="text"
            />
          </section>
          <button
            className="button mr-3 mb-3"
            id="blue"
            onClick={this.submit_edit_kode_sales}
          >
            Submit
          </button>
        </div>
      </Modal>
    );

    const modal_delete_divisi_content = modal_delete_divisi && (
      <Modal
        modalSize="small"
        isOpen={modal_delete_divisi}
        onClose={this.toggle_delete_divisi}
      >
        <div className="box-body text_center">
          <section className="text_bold mb-10">
            Hapus divisi <b>{divisi_edit}</b>
          </section>
          <button
            className="button mr-3 mb-3"
            id="blue"
            onClick={this.toggle_delete_divisi}
          >
            Batal
          </button>
          <button
            className="button"
            id="red"
            onClick={this.submit_delete_divisi}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    const item_array = [
      "ASS",
      "ASSD1",
      "ASSD2",
      "ASSD2 / D3",
      "ASSD3",
      "ASSD4",
      "ASSDA",
      "KV1DA",
      "KV2DA",
      "KVBDA",
      "SBHD1",
      "SBHD2",
      "SBHD3",
      "SKMDA",
      "TX1D1",
      "TX2D1",
      "TX2D2",
      "TX2D3",
      "TX2D4",
      "TX2D5",
      "TX2DA",
      "TX3D1",
      "TX3D2",
      "TX3D3",
      "TX3DA",
      "TX4DA",
      "WRGDA",
    ];

    const modal_edit_divisi_content = modal_edit_divisi && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_divisi}
        onClose={this.toggle_edit_divisi}
      >
        <div className="box-body">
          <section className="mb-5">
            <p className="text_bold">Edit divisi salesman</p>
            <select
              id="divisi_edit"
              name="divisi_edit"
              value={divisi_edit}
              onChange={this.on_change}
            >
              {item_array.map((v, idx) => {
                return (
                  <option key={idx} value={v}>
                    {v}
                  </option>
                );
              })}
            </select>
          </section>
          <button
            className="button mr-3 mb-3"
            id="blue"
            onClick={this.submit_edit_divisi}
          >
            Submit
          </button>
        </div>
      </Modal>
    );

    return (
      <main className="main_body" style={{ marginBottom: "100px" }}>
        {modal_radius_content}
        {modal_akuisisi_content}
        {modal_submitted_toko_content}
        {modal_urutkan_content}
        {modal_kec_content}
        {modal_kode_sales_content}
        {modal_divisi_content}
        {modal_delete_kode_sales_content}
        {modal_edit_kode_sales_content}
        {modal_delete_divisi_content}
        {modal_edit_divisi_content}

        {version_content}
        {salesman_content}
        <button
          className="button mb-3"
          id="green"
          onClick={this.toggle_urutkan}
        >
          Urutkan berdasarkan:
        </button>
        {filter_content}
        {list_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  set_menu,
  get_competitor,
  push_salesman_to_toko_new,
  set_toko_list,
  push_gagal_akuisisi,
  get_detail_depo_by_name,
  get_competitor_by_kecamatan,
  get_toko_by_multi_rayon,
  edit_kd_sales_arr,
  edit_divisi_arr,
  get_competitor_code_list,
  get_competitor_list_by_area,
  set_value,
})(Main);
