import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*PROTOTYPE*/

export const set_value = (body) => {
  /*
  body = {
  key,
  value
  }
  */
  return {
    type: "set_value",
    payload: body,
  };
};

//get_competitor_list_by_area
export const get_competitor_list_by_area = (params) => async (dispatch) => {
  try {
    const { depo } = params;
    dispatch(set_loading("get_competitor_list_by_area"));
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_competitor_list_by_area?area=depo&name=${depo}`
    );
    dispatch({
      type: "get_competitor",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*GET METHODE*/

//get_competitor_code_list
export const get_competitor_code_list = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_code_list"));
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_competitor_code_list`
    );
    dispatch({
      type: "get_competitor_code_list",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_toko_by_multi_rayon
export const get_toko_by_multi_rayon = (params) => async (dispatch) => {
  try {
    const { rayon_id_array } = params;
    dispatch(set_loading("get_competitor"));
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_toko_by_multi_rayon?rayon_id_array=${rayon_id_array}`
    );
    dispatch({
      type: "get_competitor",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_template_list
export const get_detail_depo_by_name = (params) => async (dispatch) => {
  try {
    const { depo_name } = params;
    dispatch(set_loading("get_detail_depo_by_name"));
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_detail_depo_by_name?depo_name=${depo_name}`
    );
    dispatch({
      type: "get_detail_depo_by_name",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_template_list
export const get_template_list = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_template_list"));
    const res = await axios.get(`${SERVER_URL}/nabati_per/get_template_list`);
    dispatch({
      type: "get_template_list",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_competitor_by_kecamatan
export const get_competitor_by_kecamatan = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor"));
    const { KECAMATAN, KABUPATEN_array_string } = params;
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_competitor_by_kecamatan?KECAMATAN=${KECAMATAN}&KABUPATEN_array_string=${KABUPATEN_array_string}`
    );
    dispatch({
      type: "get_competitor",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

// export const get_competitor_by_area = (params) => async (dispatch) => {
//   try {
//     dispatch(set_loading("get_competitor"));
//     const { KECAMATAN, KABUPATEN, PROPINSI } = params;
//     const res = await axios.get(
//       `${SERVER_URL}/nabati-outlet/get_competitor_by_area?KECAMATAN=${KECAMATAN}&KABUPATEN=${KABUPATEN}&PROPINSI=${PROPINSI}`
//     );
//     dispatch({
//       type: "get_competitor",
//       payload: res.data,
//     });
//     dispatch(clear_loading());
//   } catch (e) {
//     dispatch(clear_loading());
//   }
// };

//get_competitor_submitted
export const get_competitor_submitted = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_submitted"));
    const { token } = params;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_competitor_submitted`,
      config_header
    );
    dispatch({
      type: "get_competitor_submitted",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_competitor
export const get_competitor = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor"));
    let { radius, token } = params;
    radius = radius ? radius : 1;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_competitor?radius=${radius}`,
      config_header
    );
    dispatch({
      type: "get_competitor",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_salesman_detail
export const get_salesman_detail = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_salesman_detail"));
    const { token } = params;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati/get_salesman_detail`,
      config_header
    );
    dispatch({
      type: "get_salesman_detail",
      payload: res.data,
    });
    const Depo = res?.data?.Depo;
    if (Depo) {
      const res = await axios.get(
        `${SERVER_URL}/nabati_per/get_depo_by_name?depo_name=${Depo}`,
        config_header
      );
      dispatch({
        type: "get_depo_detail",
        payload: res.data,
      });
    }
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*POSTY*/

//edit_kd_sales_arr
export const edit_kd_sales_arr = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_kd_sales_arr"));
    const { kd_sales_arr, token } = body;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    await axios.get(
      `${SERVER_URL}/nabati_per/edit_kd_sales_arr?kd_sales_arr=${kd_sales_arr}`,
      config_header
    );
    dispatch({
      type: "edit_kd_sales_arr",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_divisi_arr
export const edit_divisi_arr = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_divisi_arr"));
    const { divisi_arr, token } = body;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    await axios.get(
      `${SERVER_URL}/nabati_per/edit_divisi_arr?divisi_arr=${divisi_arr}`,
      config_header
    );
    dispatch({
      type: "edit_divisi_arr",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//push_kode_sales
export const push_kode_sales = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("push_kode_sales"));
    const { kode_sales, token } = body;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    await axios.get(
      `${SERVER_URL}/nabati_per/push_kode_sales?kode_sales=${kode_sales}`,
      config_header
    );
    dispatch({
      type: "push_kode_sales",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//push_div
export const push_div = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("push_div"));
    const { divisi, token } = body;
    const config_header = {
      headers: {
        accesstoken: token,
      },
    };
    await axios.get(
      `${SERVER_URL}/nabati_per/push_div?divisi=${divisi}`,
      config_header
    );
    dispatch({
      type: "push_div",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_nama_toko
export const edit_nama_toko = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_nama_toko"));
    const { outlet_id, nama_toko, id_nabati } = body;
    await axios.get(
      SERVER_URL +
        `/nabati-outlet/edit_nama_toko?outlet_id=${outlet_id}&nama_toko=${nama_toko}&id_nabati=${id_nabati}`
    );
    dispatch(clear_loading());
    dispatch({
      type: "edit_nama_toko",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//push_gagal_akuisisi
export const push_gagal_akuisisi = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("push_gagal_akuisisi"));
    const {
      outlet_id,
      failed_reason,

      id_nabati,
      nama_toko,

      salesman_id,
      salesman_nik,
      NamaSales,

      // HARIKunjungan,
      // hari_new,
      // FreqVisit,
      // KodeSales,
      // div,
    } = body;
    await axios.get(
      SERVER_URL +
        `/nabati-outlet/push_gagal_akuisisi?outlet_id=${outlet_id}&salesman_id=${salesman_id}&salesman_nik=${salesman_nik}&NamaSales=${NamaSales}&failed_reason=${failed_reason}&id_nabati=${id_nabati}&nama_toko=${nama_toko}`
    );
    dispatch(clear_loading());
    dispatch({
      type: "push_gagal_akuisisi",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//push_salesman_to_toko_new
export const push_salesman_to_toko_new = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("push_salesman_to_toko_new"));
    const {
      nama_toko,
      outlet_id,
      id_nabati,
      salesman_id,
      salesman_nik,
      NamaSales,
      HARIKunjungan,
      hari_new,
      FreqVisit,
      KodeSales,
      div,
    } = body;
    await axios.get(
      SERVER_URL +
        `/nabati-outlet/push_salesman_to_toko_new?nama_toko=${nama_toko}&outlet_id=${outlet_id}&salesman_id=${salesman_id}&salesman_nik=${salesman_nik}&NamaSales=${NamaSales}&HARIKunjungan=${HARIKunjungan}&hari_new=${hari_new}&FreqVisit=${FreqVisit}&KodeSales=${KodeSales}&div=${div}&id_nabati=${id_nabati}`
    );
    dispatch(clear_loading());
    dispatch({
      type: "push_salesman_to_toko_new",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*NONAPI*/
export const set_toko_list = (toko_list) => {
  return {
    type: "get_competitor",
    payload: toko_list,
  };
};

export const set_loading = (loading_item) => {
  return {
    type: "set_loading",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading",
  };
};

export const set_menu = (menu) => {
  return {
    type: "set_menu",
    payload: menu,
  };
};
