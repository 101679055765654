const initial_state = {
  loading_status: false,
  loading_item: "",
  merch_list: [],
  merch_list_original: [],
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_outlet":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "set_loading_outlet":
      return { ...state, loading_status: true, loading_item: action.payload };
    case "clear_loading_outlet":
      return { ...state, loading_status: false };
    default:
      return state;
  }
}
