/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import MapGL, {
  NavigationControl,
  GeolocateControl,
  Popup,
} from "@urbica/react-map-gl";
import { bbox } from "@turf/turf";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

/*PERSONAL COMPONENT*/
import TokoLayer from "../dashboard/TokoLayer";
import TokoPopup from "../dashboard/TokoPopup";

/*REDUX FUNCTION*/
import { setMap } from "../../actions/mapActions";
import { set_menu } from "../../actions/dataActions";

/*PICTURE ASSET*/
import logoStreet from "../../img/icon_map_street.png";
import logoSatellite from "../../img/icon_map_satellite.png";
import logoLight from "../../img/icon_map_light.png";
import logoDark from "../../img/icon_map_dark.png";
import icon_list from "../../img/icon_list.png";

/*GENERAL*/

/*NON IMPORT*/

class MapView extends Component {
  _map = React.createRef();
  _draw = React.createRef();
  geocoderContainerRef = React.createRef();
  state = {
    mapStyle:
      "https://api.maptiler.com/maps/streets/style.json?key=c3oHrjQFRz1KCHlV33Eu",
    viewport: {
      latitude: -0.811123, // -0.435161, 118.009574 tengah
      longitude: 113.921327,
      zoom: 4,
    },
    outlet_list: [],
    popupInfo: null,
    list_marker: [],
    clickedFeature: {},
    drag: {
      cliked: false,
      screenX: 10,
      screenY: 10,
    },
    modalSelectMap: false,
    modalDrawComponent: false,
    mapMode: "street",
  };
  componentDidMount() {
    const map = this._map.current.getMap();
    const self = this;
    const on_touch = function (e) {
      if (e.type === "touchend") {
        let features = map.queryRenderedFeatures(
          [e.point.x, e.point.y],
          {
            passive: false,
            layers: ["toko_list-layer"],
          },
          { passive: false }
        );
        this.layerOnClick(this, {
          lngLat: e.lngLat,
          features: features,
        });
      }
    };
    map.on("touchend", "toko_list-layer", on_touch.bind(this));
    try {
      map.on("load", () => {
        map.touchZoomRotate.disableRotation();
        map.on("mouseenter", "outlet-list-lyr", () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "outlet-list-lyr", () => {
          map.getCanvas().style.cursor = "";
        });
        map.on("moveend", () => {
          const { lng, lat } = map.getCenter();
          this.setState({
            viewport: {
              ...self.state.viewport,
              longitude: lng,
              latitude: lat,
              zoom: map.getZoom(),
            },
          });
        });
        this.props.setMap(this._map.current.getMap());
      });
    } catch (e) {}
    this.setState({
      drag: {
        cliked: false,
        screenX: document.documentElement.clientWidth - 410,
        screenY: document.documentElement.clientHeight - 410,
      },
    });
    if (this.props.data.toko_list.length > 0) {
      const map = this._map.current.getMap();
      const { toko_list } = this.props.data;
      const new_features = toko_list.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      let geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      try {
        let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
        map.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: {
              top: 200,
              bottom: 200,
              left: 50,
              right: 50,
            },
          }
        );
      } catch (e) {}
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.toko_list.length !== prevProps.data.toko_list.length) {
      const map = this._map.current.getMap();
      const { toko_list } = this.props.data;
      const new_features = toko_list.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      let geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      try {
        let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
        map.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: {
              top: 200,
              bottom: 200,
              left: 50,
              right: 50,
            },
          }
        );
      } catch (e) {}
    }
    const { mapMode } = this.state;
    if (mapMode !== prevState.mapMode) {
      let mapStyle = "";
      switch (mapMode) {
        case "street":
          mapStyle =
            "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB";
          break;
        case "light":
          mapStyle = "mapbox://styles/mapbox/light-v10";
          break;
        case "dark":
          mapStyle = "mapbox://styles/mapbox/dark-v10";
          break;
        default:
          mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
      }
      this.setState({ mapStyle });
    }
    if (this.state.drag.cliked) {
      window.addEventListener("mousemove", this.sideMouseMove);
      window.addEventListener("mouseup", this.sideMouseUp);
    }
  }
  componentWillUnmount() {
    window.addEventListener("mousemove", this.sideMouseMove);
    window.addEventListener("mouseup", this.sideMouseUp);
  }
  on_set_menu = (menu) => {
    this.props.set_menu(menu);
  };
  on_get_location = () => {
    this.setState({ location_loading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.show_position);
    } else {
      return null;
    }
  };
  show_position = (position) => {
    const latitude = Number(parseFloat(position.coords.latitude).toFixed(5));
    const longitude = Number(parseFloat(position.coords.longitude).toFixed(8));
    const accuracy = parseInt(position.coords.accuracy);
    this.setState({
      lat: latitude,
      long: longitude,
      accuracy,
      location_status: true,
      location_loading: false,
      viewport: {
        latitude: latitude,
        longitude: longitude,
        zoom: 11,
      },
    });
  };
  toggleModalSelectMap = () => {
    const { modalSelectMap } = this.state;
    this.setState({ modalSelectMap: !modalSelectMap });
  };
  setSelectedMap = (val) => {
    this.setState({ mapMode: val });
  };
  on_change = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  sideMouseMove = (e) => {
    if (this.state.drag.cliked) {
      this.setState((prev) => ({
        drag: {
          ...prev.drag,
          screenX: e.clientX,
          screenY: e.clientY,
        },
      }));
    }
  };
  sideMouseUp = () => {
    this.setState((prev) => ({
      drag: { ...prev.drag, cliked: false },
    }));
  };
  sideCliked = () => {
    this.setState((prev) => ({
      drag: { ...prev.drag, cliked: true },
    }));
  };
  layerOnClick = (vari, event) => {
    try {
      const map = this._map.current.getMap();
      this.setState({ popupInfo: null, dataPopup: event });
      map.flyTo({
        center: [event.lngLat.lng, event.lngLat.lat],
      });
      this.generatePopup(vari, event);
    } catch (err) {}
  };
  generatePopup = (vari, event) => {
    try {
      let popupnya = (
        <Popup
          tipSize={7}
          anchor="bottom"
          longitude={event.lngLat.lng}
          latitude={event.lngLat.lat}
          closebutton={true}
          closeOnClick={false}
          style={{
            zIndex: 2,
          }}
          onClose={() => {
            this.setState({ modal_dis: false });
          }}
        >
          <TokoPopup event={event} on_get_location={this.on_get_location} />
        </Popup>
      );
      this.setState({
        [vari]: true,
        popupInfo: popupnya,
        clickedFeature: event.features[0],
        hari: event.features[0]?.properties?.HARIKunjungan,
        hari_new: event.features[0]?.properties?.hari_new,
      });
      return popupnya;
    } catch (err) {}
  };
  render() {
    const { modalSelectMap, mapMode, popupInfo } = this.state;
    let logoMap;
    switch (mapMode) {
      case "street":
        logoMap = logoStreet;
        break;
      case "light":
        logoMap = logoLight;
        break;
      case "dark":
        logoMap = logoDark;
        break;
      default:
        logoMap = logoSatellite;
    }
    const selectMapModeContent = (
      <div className="modal_left_side">
        <table
          style={{ textAlign: "center", width: 150, padding: 5, fontSize: 11 }}
        >
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoStreet}
                    width={50}
                    alt={"Street mode"}
                    onClick={() => this.setSelectedMap("street")}
                  />
                </div>
                <div>Street</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoSatellite}
                    width={50}
                    alt={"Satellite mode"}
                    onClick={() => this.setSelectedMap("satellite")}
                  />
                </div>
                <div>
                  <span>Satellite</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoDark}
                    width={50}
                    alt={"Dark mode"}
                    onClick={() => this.setSelectedMap("dark")}
                  />
                </div>
                <div>Dark</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoLight}
                    width={50}
                    alt={"Light mode"}
                    onClick={() => this.setSelectedMap("light")}
                  />
                </div>
                <div>Light</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    return (
      <main
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          touchAction: "none",
        }}
      >
        <section
          style={{
            backgroundImage: `url(${logoMap})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="toggle_map_mode"
          onClick={this.toggleModalSelectMap}
        >
          {modalSelectMap && (
            <div className="noselect">{selectMapModeContent}</div>
          )}
        </section>
        <section
          className="toggle_list"
          onClick={this.on_set_menu.bind(this, "list")}
        >
          <img alt="list" src={icon_list} style={{ width: "100%" }} />
        </section>
        <MapGL
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          mapStyle={this.state.mapStyle}
          accessToken={
            "pk.eyJ1IjoiZWdpcHJhdGFtYSIsImEiOiJjbGN2OGEwZDYwNHIyM3JvemFxMmJ0Y3RoIn0.QtsT--oqHJnb3A79a9c_3A"
          }
          latitude={this.state.viewport.latitude}
          longitude={this.state.viewport.longitude}
          zoom={this.state.viewport.zoom}
          maxZoom={20}
          minZoom={2}
          onViewportChange={(viewport) => {
            this.setState({ viewport });
          }}
          attributionControl={false}
          {...this.props}
          ref={this._map}
          cursorStyle={null}
          dragRotate={false}
        >
          <NavigationControl showCompass showZoom position="top-right" />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          {popupInfo}
          <TokoLayer layerOnClick={this.layerOnClick} />
        </MapGL>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  map: state.map,
  data: state.data,
});
export default connect(mapStateToProps, {
  setMap,
  set_menu,
})(MapView);
