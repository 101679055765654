const sort_array = function (array, key, isFromSmall) {
  if (isFromSmall) {
    function compare(a, b) {
      const value_a = a[key] ? a[key] : 0;
      const value_b = b[key] ? b[key] : 0;
      if (value_a < value_b) return -1;
      if (value_a > value_b) return 1;
      return 0;
    }
    return array.sort(compare);
  } else {
    function compare(a, b) {
      const value_a = a[key] ? a[key] : 0;
      const value_b = b[key] ? b[key] : 0;
      if (value_a < value_b) return 1;
      if (value_a > value_b) return -1;
      return 0;
    }
    return array.sort(compare);
  }
};
export default sort_array;
