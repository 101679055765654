export const setMap = (map) => {
  return {
    type: "SET_MAP",
    payload: map,
  };
};

export const setOutletView = (map) => {
  return {
    type: "SET_OUTLET_VIEW",
    payload: map,
  };
};

export const setTrackingView = (map) => {
  return {
    type: "SET_TRACKING_VIEW",
    payload: map,
  };
};

export const setDrawMode = (map) => {
  return {
    type: "SET_DRAW_MODE",
    payload: map,
  };
};

export const addPolygon = (map) => {
  return {
    type: "ADD_POLYGON",
    payload: map,
  };
};

export const addPointResult = (map) => {
  return {
    type: "ADD_POINT_RESULT",
    payload: map,
  };
};

export const changePolygon = (map) => {
  return {
    type: "CHANGE_POLYGON",
    payload: map,
  };
};
export const selectedDrawFeature = (map) => {
  return {
    type: "SELECTED_DRAW_FEATURE",
    payload: map,
  };
};
export const replacePoint = (map) => {
  return {
    type: "REPLACE_POINT",
    payload: map,
  };
};

export const onDelete = (map) => {
  return {
    type: "DELETE_DATA_POINT",
    payload: map,
  };
};

export const changeHari = (old, newHari) => {
  return {
    type: "CHANGE_HARI",
    payload: { old, newHari },
  };
};
