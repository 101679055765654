//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { push_kode_sales } from "../../actions/dataActions";
//Picture Asset
//General Function
class PushKodeSales extends Component {
  state = { kode_sales: "123456" };
  on_change = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  on_submit = () => {
    const { token } = this.props;
    const { kode_sales } = this.state;
    const body = {
      kode_sales,
      token,
    };
    this.props.push_kode_sales(body);
  };
  render() {
    const { kode_sales } = this.state;
    const { loading_item, loading_status } = this.props.data;
    let button_content = (
      <button className="button" id="green" onClick={this.on_submit}>
        Tambah kode sales
      </button>
    );
    if (loading_status && loading_item === "push_kode_sales") {
      button_content = (
        <button className="button" id="grey">
          Mengirim
        </button>
      );
    }
    return (
      <main className="text_center">
        <input
          type="text"
          id="kode_sales"
          name="kode_sales"
          value={kode_sales}
          onChange={this.on_change}
        />
        {button_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, { push_kode_sales })(PushKodeSales);
