/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { push_div } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class PushKodeSales extends Component {
  state = { divisi: "TX1D1" };

  on_change = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  on_submit = () => {
    const { token } = this.props;
    const { divisi } = this.state;
    const body = {
      divisi,
      token,
    };
    this.props.push_div(body);
  };

  render() {
    //local storage

    //local state
    const { divisi } = this.state;

    //global props
    const { loading_item, loading_status } = this.props.data;

    //content
    const item_array = [
      "ASS",
      "ASSD1",
      "ASSD2",
      "ASSD2 / D3",
      "ASSD3",
      "ASSD4",
      "ASSDA",
      "KV1DA",
      "KV2DA",
      "KVBDA",
      "SBHD1",
      "SBHD2",
      "SBHD3",
      "SKMDA",
      "TX1D1",
      "TX2D1",
      "TX2D2",
      "TX2D3",
      "TX2D4",
      "TX2D5",
      "TX2DA",
      "TX3D1",
      "TX3D2",
      "TX3D3",
      "TX3DA",
      "TX4DA",
      "WRGDA",
    ];
    let button_content = (
      <button className="button" id="green" onClick={this.on_submit}>
        Tambah divisi
      </button>
    );
    if (loading_status && loading_item === "push_div") {
      button_content = (
        <button className="button" id="grey">
          Mengirim
        </button>
      );
    }
    return (
      <main className="text_center">
        <select
          id="divisi"
          name="divisi"
          value={divisi}
          onChange={this.on_change}
        >
          {item_array.map((v, idx) => {
            return (
              <option key={idx} value={v}>
                {v}
              </option>
            );
          })}
        </select>
        <br />
        {button_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, { push_div })(PushKodeSales);
