/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_menu } from "../../actions/dataActions";
import { get_merch_newest_by_depo } from "../../actions/outletActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class OUTLET_MERCHANDISING_LIST extends Component {
  state = {};

  componentDidMount() {
    const { depo_detail } = this.props.data;
    const plan_id = depo_detail?.plan_id;
    if (plan_id) {
      const params = { plan_id };
      this.props.get_merch_newest_by_depo(params);
    }
  }

  on_set_menu = (menu) => {
    this.props.set_menu(menu);
  };

  render() {
    //local storage

    //local state

    //global props
    const { merch_list } = this.props.outlet;

    //content

    return (
      <main className="main_body">
        <button
          className="button margin_bottom"
          id="green"
          onClick={this.on_set_menu.bind(this, "list")}
        >
          BACK
        </button>
        <p className="text_bold">Outlet merchandising list</p>
        {merch_list.map((item, idx) => {
          const lat = item?.outlet_id?.location?.coordinates?.[1];
          const long = item?.outlet_id?.location?.coordinates?.[0];
          return (
            <main className="main_container" key={idx}>
              <section className="text_bold">
                {item?.outlet_id?.["Nama Toko"]}
              </section>
              <section style={{ marginBottom: "10px" }}>
                <p className="text_inferior">Alamat</p>
                <p className="text_medium">{item?.outlet_id?.["ALAMAT"]}</p>
                <p className="text_medium">{item?.outlet_id?.["KELURAHAN"]}</p>
                <p className="text_medium">{item?.outlet_id?.["KECAMATAN"]}</p>
                <p className="text_medium">{item?.outlet_id?.["KABUPATEN"]}</p>
              </section>
              <section>
                <p className="text_inferior">Target kelas</p>
                <p className="text_medium">{item?.["TARGET KELAS"]}</p>
              </section>
              <section>
                <p className="text_inferior">Realisasi kelas</p>
                <p className="text_medium">{item?.["REALISASI KELAS"]}</p>
              </section>
              <section>
                <p className="text_small">RASIO BUDGET PER OMSET</p>
                <p className="text_medium">
                  {item?.["RASIO BUDGET PER OMSET"]}
                </p>
              </section>
              <br />
              <a
                className="button"
                id="blue"
                href={`https://www.google.com/maps/dir//${lat},${long}/`}
              >
                Rute gmaps
              </a>
            </main>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  outlet: state.outlet,
});

export default connect(mapStateToProps, { set_menu, get_merch_newest_by_depo })(
  OUTLET_MERCHANDISING_LIST
);
