import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import history from "../src/actions/history";
import Main from "./components/pages/Main";
import store from "./store";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Router history={history}>
            <Route exact path="/" component={Main} />
          </Router>
        </div>
      </Provider>
    );
  }
}
export default App;
