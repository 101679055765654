import sort_array from "../validation/sort_array";

const initial_state = {
  /*BASIC & LOADING*/
  version: 3.26,
  radius: 1,
  loading_status: false,
  loading_item: "",
  competitor_code_list: [],

  /*DATA*/
  toko_list: [],
  toko_list_backup: [],
  toko_list_submitted: [],
  menu: "list",
  salesman_detail: {},
  template_list: [],
  depo_detail: {},
  kecamatan_list: [],
  kecamatan_selected: "All",
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "get_depo_detail":
      return {
        ...state,
        depo_detail: action.payload,
      };

    case "get_competitor_code_list":
      return {
        ...state,
        competitor_code_list: action.payload,
      };

    case "push_kode_sales":
      return {
        ...state,
        salesman_detail: push_kode_sales(state.salesman_detail, action.payload),
      };
    case "edit_kd_sales_arr":
      return {
        ...state,
        salesman_detail: edit_kd_sales_arr(
          state.salesman_detail,
          action.payload
        ),
      };
    case "edit_divisi_arr":
      return {
        ...state,
        salesman_detail: edit_divisi_arr(state.salesman_detail, action.payload),
      };
    case "push_div":
      return {
        ...state,
        salesman_detail: push_div(state.salesman_detail, action.payload),
      };
    case "edit_nama_toko":
      return {
        ...state,
        toko_list: edit_nama_toko(state.toko_list, action.payload),
        toko_list_backup: edit_nama_toko(
          state.toko_list_backup,
          action.payload
        ),
        toko_list_submitted: edit_nama_toko(
          state.toko_list_submitted,
          action.payload
        ),
      };
    case "get_template_list":
      return { ...state, template_list: action.payload };
    case "push_salesman_to_toko_new":
      return {
        ...state,
        toko_list: push_salesman_to_toko_new(state.toko_list, action.payload),
        toko_list_backup: push_salesman_to_toko_new(
          state.toko_list_backup,
          action.payload
        ),
        toko_list_submitted: submit_berhasil(
          state.toko_list_submitted,
          state.toko_list,
          action.payload
        ),
      };
    case "push_gagal_akuisisi":
      return {
        ...state,
        toko_list: push_gagal_akuisisi(state.toko_list, action.payload),
        toko_list_backup: push_gagal_akuisisi(
          state.toko_list_backup,
          action.payload
        ),
        toko_list_submitted: submit_gagal(
          state.toko_list_submitted,
          state.toko_list,
          action.payload
        ),
      };
    case "get_competitor_submitted":
      return { ...state, toko_list_submitted: action.payload };
    case "get_salesman_detail":
      return { ...state, salesman_detail: action.payload };
    case "get_competitor":
      return {
        ...state,
        toko_list: action.payload,
        toko_list_backup: action.payload,
        kecamatan_list: get_kecamatan_list(action.payload),
      };
    case "set_loading":
      return { ...state, loading_status: true, loading_item: action.payload };
    case "clear_loading":
      return { ...state, loading_status: false };
    case "set_menu":
      return { ...state, menu: action.payload };
    default:
      return state;
  }
}

const get_kecamatan_list = (toko_list) => {
  let kecamatan_list = [];
  if (toko_list.length > 0) {
    kecamatan_list = toko_list.map((t) => t.KECAMATAN);
    kecamatan_list = [...new Set(kecamatan_list)];
    kecamatan_list = kecamatan_list.map((k) => {
      return {
        KECAMATAN: k,
        toko_list_length: toko_list.filter((t) => t.KECAMATAN === k)?.length,
      };
    });
    kecamatan_list = sort_array(kecamatan_list, "KECAMATAN", true);
  }
  return kecamatan_list;
};

const edit_kd_sales_arr = (salesman, body) => {
  let { kd_sales_arr_ori } = body;
  salesman.kd_sales_arr = kd_sales_arr_ori;
  return salesman;
};

const edit_divisi_arr = (salesman, body) => {
  let { divisi_arr_ori } = body;
  salesman.divisi_arr = divisi_arr_ori;
  return salesman;
};

const push_kode_sales = (salesman, body) => {
  const { kode_sales } = body;
  let kd_sales_arr = salesman.kd_sales_arr ? salesman.kd_sales_arr : [];
  kd_sales_arr.push(kode_sales);
  salesman.kd_sales_arr = kd_sales_arr;
  return salesman;
};

const push_div = (salesman, body) => {
  const { divisi } = body;
  let divisi_arr = salesman.divisi_arr ? salesman.divisi_arr : [];
  divisi_arr.push(divisi);
  salesman.divisi_arr = divisi_arr;
  return salesman;
};

const edit_nama_toko = (list, body) => {
  let list_slice = list.slice();
  const { outlet_id, nama_toko, id_nabati } = body;
  let toko = list_slice.find((element) => element._id === outlet_id);
  toko["Nama Toko"] = nama_toko;
  toko.id_nabati = id_nabati;
  toko.edit_status = "success";
  const outlet_index = list_slice.findIndex(
    (element) => element._id === outlet_id
  );
  list_slice.splice(outlet_index, 1, toko);
  return list_slice;
};

const push_salesman_to_toko_new = (list, body) => {
  let list_slice = list.slice();
  const { outlet_id } = body;
  const kunjungan_object = body;
  let toko = list_slice.find((element) => element._id === outlet_id);
  let kunjungan_arr = toko?.kunjungan_arr ? toko?.kunjungan_arr : [];
  kunjungan_arr.push(kunjungan_object);
  const outlet_index = list_slice.findIndex(
    (element) => element._id === outlet_id
  );
  toko.acquisition_status = "success";
  list_slice.splice(outlet_index, 1, toko);
  return list_slice;
};

const push_gagal_akuisisi = (list, body) => {
  let list_slice = list.slice();
  const { outlet_id, failed_reason } = body;
  const kunjungan_object = body;
  let toko = list_slice.find((element) => element._id === outlet_id);
  let kunjungan_arr = toko?.kunjungan_arr ? toko?.kunjungan_arr : [];
  kunjungan_arr.push(kunjungan_object);
  const outlet_index = list_slice.findIndex(
    (element) => element._id === outlet_id
  );
  toko.acquisition_status = "failed";
  toko.failed_reason = failed_reason;
  list_slice.splice(outlet_index, 1, toko);
  return list_slice;
};

const submit_berhasil = (toko_list_submitted, toko_list, body) => {
  const { outlet_id, id_nabati } = body;
  let toko = toko_list.find((element) => element._id === outlet_id);
  let list_slice = toko_list_submitted.slice();
  toko.acquisition_status = "success";
  toko.id_nabati = id_nabati;
  list_slice.push(toko);
  return list_slice;
};

const submit_gagal = (toko_list_submitted, toko_list, body) => {
  const { outlet_id, failed_reason } = body;
  let toko = toko_list.find((element) => element._id === outlet_id);
  let list_slice = toko_list_submitted.slice();
  toko.acquisition_status = "failed";
  toko.failed_reason = failed_reason;
  list_slice.push(toko);
  return list_slice;
};
