const geoServerLocal = "http://localhost:4000";
const online = "https://outdigserver.nabatisnack.co.id";

const mode = "online"; // online | offline

if (mode === "offline") {
  module.exports = {
    geoServerBaseUrl: geoServerLocal,
  };
} else if (mode === "online") {
  module.exports = {
    geoServerBaseUrl: online,
  };
} else {
  module.exports = {
    geoServerBaseUrl: online,
  };
}
