/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_menu } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class MERCHANDISING_BOX extends Component {
  state = {};

  on_set_menu = (menu) => {
    this.props.set_menu(menu);
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main className="main_container">
        <p className="text_bold">Merchandising</p>
        <p className="text_small">You were selected for outlet merchandising</p>
        <button
          className="button"
          id="green"
          onClick={this.on_set_menu.bind(this, "merchandising")}
        >
          Merchandising outlet list
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { set_menu })(MERCHANDISING_BOX);
