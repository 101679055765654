import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*GET METHODE*/

//get_merch_newest_by_depo
export const get_merch_newest_by_depo = (params) => async (dispatch) => {
  try {
    dispatch(set_loading_outlet("get_merch_newest_by_depo"));
    const { plan_id } = params;
    const res = await axios.get(
      `${SERVER_URL}/properties/get_merch_newest_by_depo?plan_id=${plan_id}`
    );
    dispatch({
      type: "set_value_outlet",
      payload: { key: "merch_list", value: res.data },
    });
    dispatch({
      type: "set_value_outlet",
      payload: { key: "merch_list_original", value: res.data },
    });
    dispatch(clear_loading_outlet());
  } catch (e) {
    dispatch(clear_loading_outlet());
  }
};

/*POST METHODE*/

/*NON API*/

export const set_value_outlet = (body) => {
  /*
  body = {
    key,
    value
  }
  */
  return {
    type: "set_value_outlet",
    payload: body,
  };
};

export const set_loading_outlet = (item) => {
  return {
    type: "set_value_outlet",
    payload: item,
  };
};

export const clear_loading_outlet = () => {
  return {
    type: "clear_loading_outlet",
  };
};
